import { useEffect, useState } from 'react';
import { Channel as StreamChannel, User } from 'stream-chat';
import {
    Channel,
    ChannelHeader,
    ChannelList,
    Chat,
    MessageInput,
    MessageList,
    Thread,
    VirtualizedMessageList,
    Window,
    useCreateChatClient,
    useMessageContext,
} from 'stream-chat-react';
import { EmojiPicker } from 'stream-chat-react/emojis';

import { init, SearchIndex } from 'emoji-mart';
import data from '@emoji-mart/data';
import { useSelector } from 'react-redux';

// import './layout.css';




const Chatroom = ({ theme, switchTheme }) => {
    const globalUser = useSelector(state => state.userReducer)
    const apiKey = 'dnx889mfqyzg';
    const userId = 'na5';
    const token = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjoibmE1In0.0YFPCZfidkkOzkMniV_E35gFGbDsk6RX837YnLhpuPg';
    console.log(token)

    const filters = { members: { $in: [userId] }, type: 'messaging' };
    const options = { presence: true, state: true };
    const sort = { last_message_at: -1 };

    const client = useCreateChatClient({
        apiKey,
        tokenOrProvider: token,
        userData: { id: userId },
    });
    if (!client) return <div>Loading...</div>;

    const channel = client.channel('messaging', {
        image: 'https://cdn.com/image.png',
        name: 'Just Chatting',
        members: ['dave-matthews', 'trey-anastasio', 'na5'],
        // option to add custom fields
    });


    const CustomMessage = () => {
        const { message } = useMessageContext();
        return (
            <div>
                <b style={{ marginRight: '4px' }}>{message.user?.name}</b> {message.text}
            </div>
        );
    };

    return (
        <Chat client={client}>
            <ChannelList filters={filters} sort={sort} options={options} />
            <Channel channel={channel} EmojiPicker={EmojiPicker} emojiSearchIndex={SearchIndex} Message={CustomMessage}>
                <Window>
                    <ChannelHeader />
                    <MessageList />
                    <MessageInput />
                </Window>
                <Thread />
            </Channel>
        </Chat>

    );
}

export default Chatroom;