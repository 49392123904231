import styled from "@emotion/styled";
import { Box, Typography, keyframes } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import AuthLayout from "../components/user/auth/authLayout";
import { ShadowInput } from "../components/utils";
import { AccountCircleRounded , LockRounded } from "@mui/icons-material";
// import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ButtonPurple from "../components/buttons/buttonPurple";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { Eye, EyeSlash } from "iconsax-react";
import { ADMIN_API } from "../utils/data/admin_api";
import { getuser } from "../redux/actions";


const AuthBox = styled(Box)(({ theme }) => ({

    backgroundColor: theme.palette.secondary.bg,
    position: 'relative',
    boxSizing: 'border-box',
    width: '355px',
    height: '320px',
    borderRadius: '25px',
    display: "flex",
    flexDirection: "column", justifyContent: 'space-between', alignItems: 'center',
    padding: '25px 22px',
    "@media (max-width: 900px)": {
        height: '320px',
    },
    "@media (max-width: 600px)": {
        width: '100%',
        margin: '0 auto',
        height: '100%',
        borderRadius: '25px 25px 0px 0px',
    },
}))


const AdminAuth = () => {
    const globalUser = useSelector(state => state.userReducer)
    const navigate = useNavigate()
    const dispatch = useDispatch();
    const [buttonDisabled, setButtonDisabled] = useState(false)
    const [loading, setLoading] = useState(false)
    const [identifier, setIdentifier] = useState(undefined)
    const [password, setPassword] = useState(undefined)
    const [err, setErr] = useState(undefined)
    const [idErr, setIdErr] = useState(undefined)
    const [passErr, setPassErr] = useState(undefined)
    const [success, setSuccess] = useState(false)
    const [showPassword, setShowPassword] = useState(false)
    const apiCall = useRef(undefined)
    const fetchUser = (accesstoken) => dispatch(getuser(accesstoken));


    useEffect(() => {
        console.log(globalUser)
        if (globalUser.isLoggedIn) {
            navigate('/admin')
        }
    }, [globalUser.isLoggedIn])

    const submit = async () => {
        setErr(undefined)
        setIdErr(undefined)
        setPassErr(undefined)
        setLoading(true)
        setButtonDisabled(true)

        if (!identifier) {
            setIdErr('please enter your identifier')
            setLoading(false)
            setButtonDisabled(false)
            return
        }
        if (!password) {
            setPassErr('please enter your password')
            setLoading(false)
            setButtonDisabled(false)
            return
        }
        try {
            apiCall.current = ADMIN_API.request({
                path: `/login`,
                method: "post",
                body: { username: identifier, password: password },
            });
            let response = await apiCall.current.promise;
            if (!response.isSuccess)
                throw response
            localStorage.setItem('lastActive', true)

            console.log(response)
            let accesstoken = response.headers.cookie.match(/\/accesstoken=([^&]+)/)[1]
            fetchUser(accesstoken)
            setSuccess(response.message)
            setErr(undefined)
            setLoading(false)
            navigate('/admin')
        }
        catch (err) {
            console.log(err)
            if (err.data && err.data.message) {
                setSuccess(undefined)
                setErr(err.data.message)
                setLoading(false)
                setButtonDisabled(false)
            } else if (err.message) {
                setSuccess(undefined)
                setErr(err.message)
                setLoading(false)
                setButtonDisabled(false)
            } else {
                setSuccess(undefined)
                setErr('Network Error')
                setLoading(false)
                setButtonDisabled(false)
            }
        }
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            submit()
        }
    }
    return (
        <Box sx={{
            textTransform: 'capitalize',
            height: '100vh',
            position: 'relative',
            bgcolor: 'primary.middle',
            display: "flex", justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column', overflowY: 'scroll',
            overflowX: 'hidden',
            '&::-webkit-scrollbar': { display: 'none', },
        }}>
            <Typography sx={{ fontFamily: 'inter', fontWeight: 800, color: '#fff', fontSize: '24px', mb: '32px' }}>YouWho Admin</Typography>
            <Box sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                mt: '16px',
                mb: '32px',

            }}>
                {
                    !globalUser.isLoggedIn ?
                        <AuthBox>
                            <form
                                style={{
                                    width: '100%',
                                    display: 'flex', flexDirection: 'column',
                                    alignItems: 'center',
                                }}
                            >
                                <Box sx={{
                                    display: 'flex', flexDirection: 'column', width: '100%', alignItems: 'center', gap: '3px'
                                }}>
                                    <ShadowInput
                                        value={identifier}
                                        icon={<AccountCircleRounded  sx={{ color: 'primary.light', }} />}
                                        borderColor={idErr ? 'primary.error' : success ? 'primary.success' : undefined}
                                        onChange={(e) => setIdentifier(e.target.value)}
                                        label={'Username'} width={'99%'} id={'Username'} type="username"
                                    />
                                    {idErr ? <Typography
                                        sx={{ alignSelf: 'start !important', color: 'primary.error', fontSize: '9px', margin: 0 }}>{idErr}</Typography> : undefined}
                                    <ShadowInput
                                        onKeyDown={buttonDisabled ? undefined : handleKeyDown}
                                        mt={'12px'}
                                        icon={<LockRounded color="primary.light" sx={{ color: 'primary.light', }} />}
                                        borderColor={passErr ? 'primary.error' : success ? 'primary.success' : undefined}
                                        onChange={(e) => setPassword(e.target.value)}
                                        label={'Password'}
                                        width={'99%'}
                                        id={'Password'}
                                        value={password}
                                        type={showPassword ? 'input' : 'password'}
                                        extraIcon={
                                            <>
                                                {showPassword ?
                                                    <EyeSlash color="#787878" onClick={() => setShowPassword(false)} size='20px' />
                                                    :
                                                    <Eye size='20px' color="#787878" onClick={() => setShowPassword(true)} />
                                                }
                                            </>
                                        }
                                    />
                                    {passErr ? <Typography
                                        sx={{ alignSelf: 'start !important', color: 'primary.error', fontSize: '9px', margin: 0 }}>{passErr}</Typography> : undefined}
                                </Box>
                            </form>
                            {
                                err ?
                                    <Typography
                                        sx={{
                                            // alignSelf: 'start !important',
                                            color: 'primary.error',
                                            fontSize: '12px', my: '12px'
                                        }}>
                                        {err}
                                    </Typography> : <></>
                            }
                            <Box sx={{ width: '100%', justifySelf: 'end', }}>
                                <ButtonPurple
                                    disabled={buttonDisabled || !identifier || !password}
                                    w={'100%'}
                                    text={loading ? 'loading' : 'Sign In'}
                                    onClick={buttonDisabled ? undefined : submit}
                                />
                            </Box>
                        </AuthBox>
                        : null
                }
            </Box>
        </Box>
    );
}

export default AdminAuth;