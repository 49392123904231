import { useRef, useState } from 'react'
import AdminLayout from '../components/adminPanel/AdminLayout'
import { Box, Typography } from '@mui/material'
import Sidebar from '../components/adminPanel/sidebar';
import Appbar from '../components/adminPanel/appbar';
import ContactUsMessages from '../components/adminPanel/contactUsMessages';
import WithdrawMessages from '../components/adminPanel/withdrawMessages';
import UsersDeposites from '../components/adminPanel/usersDeposites';
import UsersWithdrawals from '../components/adminPanel/usersWithdrawals';
import UsersCheckouts from '../components/adminPanel/usersCheckouts';
import AllUsers from '../components/adminPanel/allUsers';
import ButtonPurple from '../components/buttons/buttonPurple';
import ButtonOutline from '../components/buttons/buttonOutline';
import { useDispatch, useSelector } from 'react-redux';
import { HEALTH_API } from '../utils/data/health_api';
import { useNavigate } from 'react-router';
import { logOutUser } from '../redux/actions';
import AdminDashboard from '../components/adminPanel/adminDashboard';


const AdminPanel = ({ switchTheme, theme }) => {
    const globalUser = useSelector(state => state.userReducer)
    const [open, setOpen] = useState(true);
    const [openLogoutModal, setOpenLogoutModal] = useState(false);
    const [selectedSubTab, setSelectedSubTab] = useState('dashboard')
    const apiCall = useRef(undefined)
    const dispatch = useDispatch();
    const logOut = () => dispatch(logOutUser());
    const navigate = useNavigate();

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    async function disconnect() {

        try {

            apiCall.current = HEALTH_API.request({
                path: `/logout`,
                method: "post",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${globalUser.token}`,
                }
            });
            let response = await apiCall.current.promise;

            if (!response.isSuccess)
                throw response


            logOut(globalUser.privateKey)
            setOpenLogoutModal(false)
            setTimeout(() => {
                navigate('/')
            }, 500);
        }
        catch (err) {
            logOut(globalUser.privateKey)
            setOpenLogoutModal(false)

            // console.log(err.statusText)
        }

    }

    const handleCloseLogoutModal = () => {
        setOpenLogoutModal(false)
    }

    return (
        <>
            <AdminLayout switchTheme={switchTheme} theme={theme} id={"scrollable-wallet-panel"}>
                <Appbar setOpenLogoutModal={setOpenLogoutModal} open={open} handleDrawerOpen={handleDrawerOpen} />
                <Sidebar open={open} handleDrawerClose={handleDrawerClose} handleDrawerOpen={handleDrawerOpen} selectedSubTab={selectedSubTab} setSelectedSubTab={setSelectedSubTab} />
                <Box className='red-box' sx={{ width: '100%', mb: '20px' }}>
                    {selectedSubTab === 'dashboard' ? <AdminDashboard /> : null}
                    {selectedSubTab === 'contact-us-messages' ? <ContactUsMessages /> : null}
                    {selectedSubTab === 'withdraw-messages' ? <WithdrawMessages /> : null}
                    {selectedSubTab === 'users-deposites' ? <UsersDeposites /> : null}
                    {selectedSubTab === 'users-withdrawals' ? <UsersWithdrawals /> : null}
                    {selectedSubTab === 'users-checkouts' ? <UsersCheckouts /> : null}
                    {selectedSubTab === 'all-users' ? <AllUsers /> : null}
                </Box>
            </AdminLayout>
            {
                openLogoutModal ?
                    <Box sx={{
                        width: '100%', height: '100vh', position: 'absolute', backdropFilter: 'blur(10px)', top: '0', left: '0', zIndex: '1000',
                        display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'
                    }}>
                        <Box sx={(theme) => ({
                            display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center',
                            backgroundColor: 'secondary.bg', boxShadow: theme.palette.primary.boxShadow, boxSizing: 'border-box',
                            borderRadius: { xs: '0', sm: '24px' },
                            width: { xs: '100%', sm: '400px' }, height: { xs: '100%', sm: 'auto' },
                            py: '20px', gap: '30px'
                        })}>
                            <Typography sx={{
                                color: 'secondary.text', fontSize: '16px', width: '100%', textAlign: 'center',
                                textTransform: 'capitalize'
                            }}>Are you sure you want to logout ?</Typography>
                            <Box sx={{ display: 'flex', gap: '12px', mt: '10px' }}>
                                <ButtonPurple w={'max-content'} text={'Log Me Out'} onClick={disconnect} px={'12px'} />
                                <ButtonOutline w={'max-content'} text={'Cancel'} onClick={handleCloseLogoutModal} px={'12px'} />
                            </Box>
                        </Box>
                    </Box>
                    : null
            }
        </>
    )
}

export default AdminPanel