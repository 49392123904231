import styled from "@emotion/styled";
import { Box, Modal, Typography } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { ArrowBackIos, ArrowForwardIos, Close, Face, Share } from "@mui/icons-material";
import EventAvatar from "./eventAvatar";
import users from "./users.json"
import { useDraggable } from "react-use-draggable-scroll";
import { API_CONFIG } from "../../config";
import pic from "../../assets/face-pro.svg";
import { BG_URL, PUBLIC_URL } from "../../utils/utils";
import ButtonOutline from "../buttons/buttonOutline";
import ButtonBorder from "../buttons/buttonBorder";
import ButtonPurple from "../buttons/buttonPurple";



const ProPic = styled(Box)(({ theme }) => ({
    display: 'flex', alignItems: 'center',
    borderRadius: '50%', justifyContent: 'center', backgroundRepeat: 'no-repeat', transition: '500ms ease'
}))
const ProBanner = styled(Box)(({ theme }) => ({
    borderRadius: '24px',
    display: 'flex', alignItems: 'center', boxSizing: 'border-box',
    boxShadow: theme.palette.primary.boxShadow, transition: '500ms ease'
}))


const ProfileSlider = ({ id, className }) => {
    const globalUser = useSelector(state => state.userReducer)
    const ref = useRef();
    const { events } = useDraggable(ref);
    const [selectedProfile, setSelectedProfile] = useState(null)
    const [selectedIndex, setSelecIndex] = useState(null)
    const [openModal, setOpenModal] = useState(false)
    const profiles = users.data.toReversed()

    // useEffect(() => {
    //     if (users)
    //         console.log(users)
    // }, [users])

    const handleOpenModal = (profile, index) => {
        setSelectedProfile(profile)
        setSelecIndex(index)
        setOpenModal(true)
    }
    const handleCloseModal = () => {
        setSelectedProfile(null)
        setSelecIndex(null)
        setOpenModal(false)
    }

    const handleForwardStory = () => {
        if (selectedIndex < profiles.length - 1) {
            setSelectedProfile(profiles[selectedIndex + 1])
            setSelecIndex(state => state + 1)
        }
    }
    const handleBackStory = () => {
        if (selectedIndex > 0) {
            setSelectedProfile(profiles[selectedIndex - 1])
            setSelecIndex(state => state - 1)
        }

    }


    return (
        <Box
            {...events}
            sx={{
                display: 'flex',
                flexWrap: 'nowrap',
                gap: '12px',
                overflowX: 'auto',
                width: '100%',
                minHeight: '72px',
                px: { xs: '0', md: '30px' },
                pb: '8px',
                '&::-webkit-scrollbar': {
                    height: '4px',
                    width: '3px',
                    background: 'transparent',
                    border: 'none',
                    borderRadius: '20px !important'
                },
                '&::-webkit-scrollbar-thumb': {
                    width: '3px',
                    height: '3px',
                    background: '#9747ff',
                    border: '0.5px solid #9747ff',
                    borderRadius: '20px !important'
                },
                '&::-webkit-scrollbar-button': {
                    display: 'none',
                    width: '1px',
                    height: '1px',
                    background: '#9747ff',
                    border: '0.5px solid #C6BAC5',
                    borderRadius: '50% !important'
                },
                "@media (max-width: 600px)": {
                    '&::-webkit-scrollbar': {
                        display: 'none',
                    },
                },
            }}
            ref={ref}
            id={id}
            className={className}
        >
            {
                profiles.map((user, index) => (
                    <EventAvatar user={user} index={index} handleOpenModal={handleOpenModal} />
                ))
            }
            <Modal
                open={openModal}
                onClose={handleCloseModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                disableScrollLock={true}

            >
                <Box sx={(theme) => ({
                    width: '100%',
                    height: '100%',
                    display: 'flex', alignItems: 'center', justifyContent: 'center',
                    backdropFilter: 'blur(10px)', pointerEvents: 'none'
                })}>
                    <Box sx={(theme) => ({
                        borderRadius: { xs: '0', sm: '24px' },
                        width: { xs: '100%', sm: 'max-content' }, height: { xs: '100%', sm: 'auto' },
                        backgroundColor: 'secondary.bg', boxShadow: theme.palette.primary.boxShadow, boxSizing: 'border-box',
                        display: 'flex', flexDirection: 'column',
                        padding: '18px 24px', alignItems: 'flex-end', pointerEvents: 'all', 
                    })}>
                        <div onClick={() => setOpenModal(false)}>
                            <Close sx={{cursor:'pointer', fontSize:'20px'}}/>
                        </div>
                        <Box sx={(theme) => ({
                            width: '100%', gap: '20px', display: 'flex', justifyContent: 'space-between', alignItems: 'center',pb:'12px'
                        })}>
                            <div onClick={handleBackStory}>
                                <ArrowBackIos sx={{ fontSize: '26px', color: '#9A9A9A', cursor: 'pointer' }} />
                            </div>
                            <Box sx={(theme) => ({
                                borderRadius: { xs: '0', sm: '24px' },
                                width: '200px', aspectRatio: '4/7',
                                backgroundColor: 'primary.main',
                                p: "8px"
                            })}>
                                <Box sx={{ width: '100%', display: 'flex', gap: '8px', alignItems: 'center' }}>
                                    {selectedProfile ?
                                        <>
                                            <Box
                                                sx={{
                                                    // background: selectedProfile.avatar ? `url('${API_CONFIG.API_URL}/${selectedProfile.avatar}')` : `${BG_URL(PUBLIC_URL(`${pic}`))} no-repeat center`,
                                                    background: selectedProfile.avatar ? `url(${selectedProfile.avatar})` : `${BG_URL(PUBLIC_URL(`${pic}`))} no-repeat center`,
                                                    borderRadius: '50%',
                                                    width: '50px', height: '50px',
                                                    backgroundSize: '50px 50px', cursor: 'pointer'
                                                }}
                                            >
                                            </Box>
                                            <Typography sx={{ width: '100px', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>{selectedProfile.username}</Typography>
                                        </>
                                        : null}
                                </Box>
                            </Box>
                            <div onClick={handleForwardStory}>
                                <ArrowForwardIos sx={{ fontSize: '26px', color: '#9A9A9A', cursor: 'pointer' }} />
                            </div>
                        </Box>
                        <Box sx={(theme) => ({
                            width: '100%', display: 'flex', flexDirection: 'column', gap: '12px', pb:'12px'
                        })}>
                            <Box sx={{ display: 'flex', width: '100%', gap: '5px', justifyContent: 'space-between' }}>
                                <Typography sx={{ textAlign: 'center', color: 'primary.text', fontSize: '16px', color: '#787878' }}>Start Date-Time: </Typography>
                                <Typography sx={{ textAlign: 'center', color: 'primary.text', fontSize: '16px' }}>2024/04/23  18:00</Typography>
                            </Box>
                            <Box sx={{ display: 'flex', width: '100%', gap: '5px', justifyContent: 'space-between' }}>
                                <Typography sx={{ textAlign: 'center', color: 'primary.text', fontSize: '16px', color: '#787878' }}>Event Capacity: </Typography>
                                <Typography sx={{ textAlign: 'center', color: 'primary.text', fontSize: '16px' }}>70 People</Typography>
                            </Box>
                            <Box sx={{ display: 'flex', width: '100%', gap: '5px', justifyContent: 'space-between' }}>
                                <Typography sx={{ textAlign: 'center', color: 'primary.text', fontSize: '16px', color: '#787878' }}>Subject: </Typography>
                                <Typography sx={{ textAlign: 'center', color: 'primary.text', fontSize: '16px' }}>Best Insurance Company</Typography>
                            </Box>
                            <Box sx={{ display: 'flex', width: '100%', gap: '5px', justifyContent: 'space-between' }}>
                                <Typography sx={{ textAlign: 'center', color: 'primary.text', fontSize: '16px', color: '#787878' }}>Duration: </Typography>
                                <Typography sx={{ textAlign: 'center', color: 'primary.text', fontSize: '16px', maxWidth: '250px', textAlign: 'left' }}>2 Hours</Typography>
                            </Box>
                        </Box>
                        <ButtonPurple w={'100%'} text={'Join Event'} />
                    </Box>
                </Box>
            </Modal>
        </Box>
    );
}

export default ProfileSlider;