import { AppBar, Divider, Drawer, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Toolbar, Typography } from '@mui/material'
import { ChevronLeft, Groups, Inbox, LogoutOutlined, MailOutline, Menu } from '@mui/icons-material';
import styled from '@emotion/styled';
import { Box } from 'iconsax-react';

const drawerWidth = 240;


const AppBarStyle = styled(AppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));


const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
    boxShadow: '0px 4px 10px -16px rgba(0, 0, 0, 0.25)',
}))



const Appbar = ({ open, handleDrawerOpen, setOpenLogoutModal }) => {
    return (
        <AppBarStyle position="absolute" open={open}>
            <ToolbarStyle sx={{ bgcolor: 'white', boxShadow: '', display: 'flex', justifyContent: 'space-between' }}>
                <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    onClick={handleDrawerOpen}
                    edge="start"
                    sx={{
                        marginRight: 5,
                        color: 'primary.main',
                        ...(open && { display: 'none' }),
                    }}
                >
                    <Menu />
                </IconButton>
                <Box></Box>
                <LogoutOutlined onClick={() => setOpenLogoutModal(true)} sx={{ fontSize: '24px', color: 'primary.main', cursor: 'pointer' }} />
            </ToolbarStyle>
        </AppBarStyle>
    )
}

export default Appbar