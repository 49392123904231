import { Box, Typography, styled, Modal } from '@mui/material'
import { useEffect, useRef, useState } from 'react'
import EventSlider from './eventSlider';
import EventCard from './eventCard';
import { BulletFiltering } from '../utils';
import ButtonOutline from '../buttons/buttonOutline';
import ButtonBorder from '../buttons/buttonBorder';
import { Chat, Close, ContentPaste, Delete, DirectionsWalk, LocalCafe, LocalCafeRounded, Login, Share } from '@mui/icons-material';
import SingleEventAccordion from './singleEventAccordion';
import ButtonPurple from '../buttons/buttonPurple';
import { DocumentDownload } from 'iconsax-react';
import instagram from '../../assets/instagram.svg'
import xIcon from '../../assets/x.svg'
import copy from '../../assets/copy.svg'
import telegram from '../../assets/telegram.svg'
import whatsapp from '../../assets/whatsapp.svg'

const Container = styled(Box)(({ theme }) => ({
    boxSizing: 'border-box',
    width: '100%',
    display: 'flex',
    flexWrap: 'nowrap',
    gap: '12px',
    overflowX: 'auto',
    padding: '8px 0px',
    '&::-webkit-scrollbar': {
        height: '4px',
        width: '3px',
        background: 'transparent',
        border: 'none',
        borderRadius: '20px !important'
    },
    '&::-webkit-scrollbar-thumb': {
        width: '3px',
        height: '3px',
        background: '#9747ff',
        border: '0.5px solid #9747ff',
        borderRadius: '20px !important'
    },
    '&::-webkit-scrollbar-button': {
        display: 'none',
        width: '1px',
        height: '1px',
        background: '#9747ff',
        border: '0.5px solid #C6BAC5',
        borderRadius: '50% !important'
    },
    "@media (max-width: 600px)": {
        '&::-webkit-scrollbar': {
            display: 'none',
        },
    },
}))
const FilterSelectionBox = styled(Box)(({ theme }) => ({
    display: 'flex', boxSizing: 'border-box',
    flexDirection: 'row',
    alignItems: 'center',
    cursor: 'pointer',
    justifyContent: 'start',
    border: '1px solid #DEDEDE',
    borderRadius: '18px',
    overflow: 'hidden',
    // height: '20px',
    color: theme.palette.primary.text,
    backgroundColor: theme.palette.secondary.bg,
    width: '100%'
}))

const SingleEvent = styled(Box)(({ theme }) => ({
    boxSizing: 'border-box',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: theme.palette.secondary.bg,
    padding: '8px 12px 12px 8px',
    gap: '40px',
    borderRadius: '18px', transition: '500ms ease',
    boxShadow: theme.palette.primary.boxShadow,
    "@media (max-width: 600px)": {
        padding: '8px',
        gap: '20px',
    },
    marginBottom: '30px'
}))

const MyEvents = ({ filterOpen }) => {
    const [pricefilterValue, setPriceFilterValue] = useState('all')
    const [stateFilterValue, setStateFilterValue] = useState('all')
    const [sortingFilterValue, setSortingFilterValue] = useState('sort-by-ascending-(time)')
    const priceFilterOptions = ['all', 'free-ones', 'non-free-ones']
    const statusFilterOptions = ['all', 'finished', 'pending', 'rejecteds']
    const SortingFilterOptions = ['sort-by-ascending-(time)', 'sort-by-descending-(time)']
    const [openModal, setOpenModal] = useState(false)

    useEffect(() => {
        window.document.getElementById("scrollable-events-panel-inside").scrollTo(0, 0);

    }, [])

    const handleShare = () => {
        setOpenModal(true)
    }

    return (
        <Box sx={{
            width: '100%',
            maxWidth: '1000px',
            display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '24px', p: '0', mb: '10px', mt: '10px'
        }}>
            <Box sx={{
                width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center',
                height: filterOpen ? '230px' : '0px', transition: '0.3s'
            }}>
                <Box sx={{
                    width: { xs: '100%', sm: '80%', md: '70%' }, boxSizing: 'border-box',
                    overflow: 'hidden',
                    display: filterOpen ? 'flex' : 'none',
                    gap: { xs: '8px', md: '16px' }, flexDirection: 'column'
                }}>
                    <FilterSelectionBox sx={{ padding: '8px 16px', }}>
                        <span style={{ width: 'max-content', whiteSpace: 'nowrap', fontSize: '14px', paddingRight: '16px' }}>
                            Event Type:
                        </span>
                        <input
                            style={{
                                height: '20px',
                                backgroundColor: 'transparent', border: 'none', outline: 'none',
                                color: '#c2c2c2', width: '100%'
                            }}
                            onChange={() => { }}
                            placeholder='All Types Of Events'
                        />
                    </FilterSelectionBox>
                    <BulletFiltering setOption={setPriceFilterValue} options={priceFilterOptions} selected={pricefilterValue} fontSize={'14px'} width={'100%'} />
                    <BulletFiltering setOption={setStateFilterValue} options={statusFilterOptions} selected={stateFilterValue} fontSize={'14px'} width={'100%'} />
                    <BulletFiltering setOption={setSortingFilterValue} options={SortingFilterOptions} selected={sortingFilterValue} fontSize={'14px'} width={'100%'} />
                </Box>
            </Box>
            <Box sx={{
                width: '100%',
                maxWidth: '720px',
                display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '8px', p: '0', mb: '10px', mt: '10px'
            }}>
                {/* started chatroom event */}
                <SingleEvent sx={{ flexDirection: { xs: 'column', sm: 'row' } }}>
                    <Box sx={{ width: { xs: '100px', sm: '56px' }, aspectRatio: '4/7', backgroundColor: 'primary.main', borderRadius: '8px' }}></Box>
                    <Box sx={{ flexGrow: '1', display: 'flex', flexDirection: 'column', gap: '16px' }}>
                        <Box sx={{ display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'center', gap: '8px' }}>
                            <Typography sx={{ color: '#4C00AF', fontWeight: '700', display: 'flex', alignItems: 'center', gap: '5px' }}>
                                <Chat />
                                Chatroom
                            </Typography>
                            <Typography sx={{ fontFamily: 'inter' }}><b>15 : 47’ : 59”</b> To Close</Typography>
                        </Box>
                        <Box sx={{ display: 'flex', width: '100%', flexDirection: { xs: 'column', sm: 'row' }, justifyContent: 'space-between', alignItems: 'center' }}>
                            <Typography>Gift for Sarah's birthday</Typography>
                            <Typography sx={{ fontWeight: '700', fontFamily: 'inter' }}>Ali1990</Typography>
                        </Box>
                    </Box>
                    <ButtonBorder bgcolor='#FFD966' br='30px' text='Enter' fontColor='black' prevIcon={<Login />} />
                </SingleEvent>

                {/* upcoming chatroom event */}
                <SingleEventAccordion height='240px'
                    details={
                        <Box sx={{ display: 'flex', width: '100%' }}>
                            <Box sx={{ display: 'flex', flexGrow: '1', flexDirection: 'column', p: '16px  0px 16px 16px', gap: '8px' }}>
                                <Typography sx={{ fontSize: '14px' }}><b>Entrance Fee: </b>1,500 Token</Typography>
                                <Typography sx={{ fontSize: '14px' }}><b>for: </b>My Freinds</Typography>
                                <Typography sx={{ fontSize: '14px' }}><b>Description: </b>Description Description Description Description Description Description Description Description Description Description Description Description Description Description Description Description</Typography>
                                <Typography sx={{ fontSize: '14px' }}><b>Entrance Fee For Invited Friends: </b>Free</Typography>
                                <Typography sx={{ fontSize: '14px' }}><b>Invited Friends: </b></Typography>
                                <Box sx={{ display: 'flex', width: '100%', gap: '8px' }}>
                                    <Typography sx={{ fontSize: '14px', bgcolor: '#D7BDE7', color: '#4C00AF', borderRadius: '8px', padding: '4px 8px' }}>Soghra</Typography>
                                    <Typography sx={{ fontSize: '14px', bgcolor: '#D7BDE7', color: '#4C00AF', borderRadius: '8px', padding: '4px 8px' }}>Asghar</Typography>
                                    <Typography sx={{ fontSize: '14px', bgcolor: '#D7BDE7', color: '#4C00AF', borderRadius: '8px', padding: '4px 8px' }}>Akbar</Typography>
                                    <Typography sx={{ fontSize: '14px', bgcolor: '#D7BDE7', color: '#4C00AF', borderRadius: '8px', padding: '4px 8px' }}>Sara</Typography>
                                    <Typography sx={{ fontSize: '14px', bgcolor: '#D7BDE7', color: '#4C00AF', borderRadius: '8px', padding: '4px 8px' }}>Ali</Typography>

                                </Box>
                            </Box>
                            <Box sx={{ display: 'flex', flexDirection: 'column', p: '16px', gap: '8px' }}>
                                <ButtonPurple onClick={handleShare} height='36px' w={'100px'} fontSize='14px' br='30px' text='Share' prevIcon={<Share sx={{ fontSize: '14px' }} />} />
                                <ButtonOutline height='36px' w={'100px'} fontSize='14px' br='30px' text='Cancel' prevIcon={<Delete sx={{ fontSize: '14px' }} />} />
                            </Box>
                        </Box>
                    }
                >
                    <Box sx={{ width: { xs: '100px', sm: '56px' }, aspectRatio: '4/7', backgroundColor: 'primary.main', borderRadius: '8px' }}></Box>
                    <Box sx={{ flexGrow: '1', display: 'flex', flexDirection: 'column', gap: '16px' }}>
                        <Box sx={{ display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'center', gap: '8px' }}>
                            <Typography sx={{ color: '#4C00AF', fontWeight: '700', display: 'flex', alignItems: 'center', gap: '5px' }}>
                                <Chat />
                                Chatroom
                            </Typography>
                            <Typography sx={{ fontFamily: 'inter' }}><b>15 : 47’ : 59”</b> To Open</Typography>
                        </Box>
                        <Box sx={{ display: 'flex', width: '100%', flexDirection: { xs: 'column', sm: 'row' }, justifyContent: 'space-between', alignItems: 'center' }}>
                            <Typography>Gift for Sarah's birthday</Typography>
                            <Typography sx={{ fontWeight: '400', color: 'primary.darkGray', fontFamily: 'inter', fontSize: '14px' }}>10.05.2024  10:10 PM</Typography>
                        </Box>
                        <Box sx={{ display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'center', gap: '8px' }}>
                            <Typography sx={{ fontFamily: 'inter' }}>10/50 Joined</Typography>
                        </Box>
                    </Box>
                </SingleEventAccordion>

                {/* Joined Proposal event */}
                <SingleEventAccordion height='240px'
                    details={
                        <Box sx={{ display: 'flex', width: '100%' }}>
                            <Box sx={{ display: 'flex', flexGrow: '1', flexDirection: 'column', p: '16px  0px 16px 16px', gap: '8px' }}>
                                <Typography sx={{ fontSize: '14px' }}><b>Entrance Fee: </b>1,500 Token</Typography>
                                <Typography sx={{ fontSize: '14px' }}><b>for: </b>My Freinds</Typography>
                                <Typography sx={{ fontSize: '14px' }}><b>Description: </b>Description Description Description Description Description Description Description Description Description Description Description Description Description Description Description Description</Typography>
                                <Typography sx={{ fontSize: '14px' }}><b>Entrance Fee For Invited Friends: </b>Free</Typography>
                                <Typography sx={{ fontSize: '14px' }}><b>Invited Friends: </b></Typography>
                                <Box sx={{ display: 'flex', width: '100%', gap: '8px' }}>
                                    <Typography sx={{ fontSize: '14px', bgcolor: '#D7BDE7', color: '#4C00AF', borderRadius: '8px', padding: '4px 8px' }}>Soghra</Typography>
                                    <Typography sx={{ fontSize: '14px', bgcolor: '#D7BDE7', color: '#4C00AF', borderRadius: '8px', padding: '4px 8px' }}>Asghar</Typography>
                                    <Typography sx={{ fontSize: '14px', bgcolor: '#D7BDE7', color: '#4C00AF', borderRadius: '8px', padding: '4px 8px' }}>Akbar</Typography>
                                    <Typography sx={{ fontSize: '14px', bgcolor: '#D7BDE7', color: '#4C00AF', borderRadius: '8px', padding: '4px 8px' }}>Sara</Typography>
                                    <Typography sx={{ fontSize: '14px', bgcolor: '#D7BDE7', color: '#4C00AF', borderRadius: '8px', padding: '4px 8px' }}>Ali</Typography>

                                </Box>
                            </Box>
                            <Box sx={{ display: 'flex', flexDirection: 'column', p: '16px', gap: '8px' }}>
                                <ButtonPurple onClick={handleShare} height='36px' w={'100px'} fontSize='14px' br='30px' text='Share' prevIcon={<Share sx={{ fontSize: '14px' }} />} />
                            </Box>
                        </Box>
                    }
                >
                    <Box sx={{ width: { xs: '100px', sm: '56px' }, aspectRatio: '4/7', backgroundColor: 'primary.main', borderRadius: '8px' }}></Box>
                    <Box sx={{ flexGrow: '1', display: 'flex', flexDirection: 'column', gap: '16px' }}>
                        <Box sx={{ display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'center', gap: '8px' }}>
                            <Typography sx={{ color: '#4C00AF', fontWeight: '700', display: 'flex', alignItems: 'center', gap: '5px' }}>
                                <Chat />
                                Chatroom
                            </Typography>
                            <Typography sx={{ fontFamily: 'inter' }}><b>15 : 47’ : 59”</b> To Open</Typography>
                        </Box>
                        <Box sx={{ display: 'flex', width: '100%', flexDirection: { xs: 'column', sm: 'row' }, justifyContent: 'space-between', alignItems: 'center' }}>
                            <Typography>Gift for Sarah's birthday</Typography>
                            <Typography sx={{ fontWeight: '400', color: 'primary.darkGray', fontFamily: 'inter', fontSize: '14px' }}>10.05.2024  10:10 PM</Typography>
                        </Box>
                        <Box sx={{ display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'center', gap: '8px' }}>
                            <Typography sx={{ fontFamily: 'inter' }}>10/50 Joined</Typography>
                        </Box>
                    </Box>
                </SingleEventAccordion>

                {/* Finished Proposal event */}
                <SingleEventAccordion height='140px'
                    details={
                        <Box sx={{ display: 'flex', width: '100%' }}>
                            <Box sx={{ display: 'flex', flexGrow: '1', flexDirection: 'column', p: '16px  0px 16px 16px', gap: '8px' }}>
                                <Typography sx={{ fontSize: '14px' }}><b>Entrance Fee: </b>1,500 Token</Typography>
                                <Typography sx={{ fontSize: '14px' }}><b>For: </b>My Freinds</Typography>
                                <Typography sx={{ fontSize: '14px' }}><b>Description: </b>Description Description Description Description Description</Typography>
                                <Typography sx={{ fontSize: '14px' }}><b>Earned Token: </b>10,000 Token</Typography>
                            </Box>
                            <Box sx={{ display: 'flex', flexDirection: 'column', p: '16px', gap: '8px' }}>
                                <ButtonPurple height='36px' w={'100px'} fontSize='14px' br='30px' text='PDF' prevIcon={<DocumentDownload sx={{ fontSize: '14px' }} />} />
                            </Box>
                        </Box>
                    }
                >
                    <Box sx={{ width: { xs: '100px', sm: '56px' }, aspectRatio: '4/7', backgroundColor: 'primary.main', borderRadius: '8px' }}></Box>
                    <Box sx={{ flexGrow: '1', display: 'flex', flexDirection: 'column', gap: '16px' }}>
                        <Box sx={{ display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'center', gap: '8px' }}>
                            <Typography sx={{ color: '#4C00AF', fontWeight: '700', display: 'flex', alignItems: 'center', gap: '5px' }}>
                                <Chat />
                                Chatroom
                            </Typography>
                            <Typography sx={{ fontWeight: '700', fontFamily: 'inter' }}>
                                ✓ Finished
                            </Typography>
                            <Typography sx={{ fontFamily: 'inter' }}><b>15 : 47’ : 59”</b> To Open</Typography>
                        </Box>
                        <Box sx={{ display: 'flex', width: '100%', flexDirection: { xs: 'column', sm: 'row' }, justifyContent: 'space-between', alignItems: 'center' }}>
                            <Typography>Gift for Sarah's birthday</Typography>
                            <Typography sx={{ fontWeight: '400', color: 'primary.darkGray', fontFamily: 'inter', fontSize: '14px' }}>10.05.2024  10:10 PM</Typography>
                        </Box>
                        <Box sx={{ display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'center', gap: '8px' }}>
                            <Typography sx={{ fontFamily: 'inter' }}>10/50 Joined</Typography>
                        </Box>
                    </Box>
                </SingleEventAccordion>

                {/* Leaved Proposal event */}
                <SingleEventAccordion height='220px'
                    details={
                        <Box sx={{ display: 'flex', width: '100%' }}>
                            <Box sx={{ display: 'flex', flexGrow: '1', flexDirection: 'column', p: '16px  0px 16px 16px', gap: '8px' }}>
                                <Typography sx={{ fontSize: '14px' }}><b>Entrance Fee: </b>1,500 Token</Typography>
                                <Typography sx={{ fontSize: '14px' }}><b>For: </b>My Freinds</Typography>
                                <Typography sx={{ fontSize: '14px' }}><b>Entrance Fee For Invited Freinds: </b>500 Token</Typography>
                                <Typography sx={{ fontSize: '14px' }}><b>Description: </b>Description Description Description Description Description</Typography>
                                Z                                <Typography sx={{ fontSize: '14px' }}><b>Proposal Type: </b>Leave Comment</Typography>
                                <Typography sx={{ fontSize: '14px' }}><b>Questions: </b>Do you know a good cafe in west of Tehran?</Typography>
                            </Box>
                            <Box sx={{ display: 'flex', flexDirection: 'column', p: '16px', gap: '8px' }}>
                                <ButtonPurple height='36px' w={'100px'} fontSize='14px' br='30px' text='PDF' prevIcon={<DocumentDownload sx={{ fontSize: '14px' }} />} />
                            </Box>
                        </Box>
                    }
                >
                    <Box sx={{ width: { xs: '100px', sm: '56px' }, aspectRatio: '4/7', backgroundColor: 'primary.main', borderRadius: '8px' }}></Box>
                    <Box sx={{ flexGrow: '1', display: 'flex', flexDirection: 'column', gap: '16px' }}>
                        <Box sx={{ display: 'flex', width: '100%', justifyContent: 'flex-start', alignItems: 'center', gap: '16px', flexDirection: { xs: 'column', sm: 'row' }, }}>
                            <Typography sx={{ color: '#4C00AF', fontWeight: '700', display: 'flex', alignItems: 'center', gap: '5px' }}>
                                <ContentPaste />
                                Proposal
                            </Typography>
                            <Typography sx={{ fontWeight: '700', fontFamily: 'inter' }}>
                                ✓ Finished
                            </Typography>
                            <Typography sx={{ color: '#F675A8', fontFamily: 'inter', flexGrow: '1', textAlign: 'right', fontSize: '14px' }}>
                                You Leaved This Event
                            </Typography>
                        </Box>
                        <Box sx={{ display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'center', flexDirection: { xs: 'column', sm: 'row' } }}>
                            <Typography>Gift for Sarah's birthday</Typography>
                            <Typography sx={{ fontWeight: '400', color: 'primary.darkGray', fontFamily: 'inter', fontSize: '14px' }}>10.05.2024  10:10 PM</Typography>
                        </Box>
                        <Box sx={{ display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'center', gap: '8px' }}>
                            <Typography sx={{ fontFamily: 'inter' }}>10/50 Joined</Typography>
                        </Box>
                    </Box>
                </SingleEventAccordion>

                {/* Social event */}
                <SingleEventAccordion height='210px'
                    details={
                        <Box sx={{ display: 'flex', width: '100%' }}>
                            <Box sx={{ display: 'flex', flexGrow: '1', flexDirection: 'column', p: '16px  0px 16px 16px', gap: '8px' }}>
                                <Typography sx={{ fontSize: '14px' }}><b>Entrance Fee: </b>1,500 Token</Typography>
                                <Typography sx={{ fontSize: '14px' }}><b>For: </b>My Freinds</Typography>
                                <Typography sx={{ fontSize: '14px' }}><b>Entrance Fee For Invited Freinds: </b>500 Token</Typography>
                                <Typography sx={{ fontSize: '14px' }}><b>Description: </b>Description Description Description Description Description</Typography>
                                <Typography sx={{ fontSize: '14px' }}><b>Address: </b>Address Address Address Address Address Address Address Address Address Address Address</Typography>
                            </Box>
                            <Box sx={{ display: 'flex', flexDirection: 'column', p: '16px', gap: '8px' }}>
                                <ButtonPurple height='36px' w={'100px'} fontSize='14px' br='30px' text='PDF' prevIcon={<DocumentDownload sx={{ fontSize: '14px' }} />} />
                            </Box>
                        </Box>
                    }
                >
                    <Box sx={{ width: { xs: '100px', sm: '56px' }, aspectRatio: '4/7', backgroundColor: 'primary.main', borderRadius: '8px' }}></Box>
                    <Box sx={{ flexGrow: '1', display: 'flex', flexDirection: 'column', gap: '16px' }}>
                        <Box sx={{ display: 'flex', width: '100%', justifyContent: 'flex-start', alignItems: 'center', gap: '16px', flexDirection: { xs: 'column', sm: 'row' }, }}>
                            <Typography sx={{ color: '#4C00AF', fontWeight: '700', display: 'flex', alignItems: 'center', gap: '5px' }}>
                                <LocalCafeRounded />
                                Social Gathering
                            </Typography>
                            <Typography sx={{ fontWeight: '700', fontFamily: 'inter' }}>
                                ✓ Finished
                            </Typography>
                            <Typography sx={{ color: '#F675A8', fontFamily: 'inter', flexGrow: '1', textAlign: 'right', fontSize: '14px' }}>
                                You Leaved This Event
                            </Typography>
                        </Box>
                        <Box sx={{ display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'center', flexDirection: { xs: 'column', sm: 'row' } }}>
                            <Typography>Visit free museums</Typography>
                            <Typography sx={{ fontWeight: '400', color: 'primary.darkGray', fontFamily: 'inter', fontSize: '14px' }}>10.05.2024  10:10 PM</Typography>
                        </Box>
                        <Box sx={{ display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'center', gap: '8px' }}>
                            <Typography sx={{ fontFamily: 'inter' }}>10/50 Joined</Typography>
                        </Box>
                    </Box>
                </SingleEventAccordion>
            </Box >

            <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '12px', mt: '32px' }}>
                <Typography sx={{ color: 'primary.text', textAlign: 'left', fontSize: '22px', lineHeight: 'normal', fontWeight: '400', width: '100%' }}>
                    Events You Invited
                </Typography>
            </Box>
            <EventSlider>
                <EventCard bgColor={'#FFFFFF'} />
                <EventCard bgColor={'#C3FBC6'} />
                <EventCard bgColor={'#FFFFFF'} />
                <EventCard bgColor={'#C3FBC6'} />
            </EventSlider>

            <Modal
                open={openModal}
                onClose={() => setOpenModal(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                disableScrollLock={true}

            >
                <Box sx={(theme) => ({
                    width: '100%',
                    height: '100%',
                    display: 'flex', alignItems: 'center', justifyContent: 'center',
                    backdropFilter: 'blur(10px)', pointerEvents: 'none'
                })}>
                    <Box sx={{
                        borderRadius: { xs: '0', sm: '24px' },
                        width: { xs: '100%', sm: '420px' },
                        height: { xs: '100%', sm: 'auto' },
                        backgroundColor: 'secondary.bg',
                        boxShadow: 'primary.boxShadow',
                        boxSizing: 'border-box',
                        display: 'flex',
                        flexDirection: 'column',
                        padding: '30px',
                        alignItems: 'center',
                        pointerEvents: 'all',
                        gap: '24px',
                    }}>
                        <div style={{ width: '100%', display: 'flex', justifyContent: 'end' }} onClick={() => setOpenModal(false)}>
                            <Close sx={{ cursor: 'pointer' }} />
                        </div>
                        <Typography sx={{ fontSize: '22px', textAlign: 'center', width: '100%', display: 'flex', alignItems: 'center', gap: '4px', justifyContent: 'center' }}>
                            <LocalCafeRounded sx={{ color: '#4C00AF', fontSize: '34px' }} />
                            Sharing Event
                        </Typography>

                        <Box sx={{ width: '120px', aspectRatio: '4/7', backgroundColor: 'primary.main', borderRadius: '8px' }}></Box>

                        <Typography sx={{ fontSize: '14px', textAlign: 'center', width: '100%' }}>https://YouWho.club/Negin/Event/dertftyghguijkj</Typography>

                        <Box sx={{ width: '90%', display: 'flex', backgroundColor: '#DEDEDE', height: '100px', padding: '12px', borderRadius: '12px', height: '70px', justifyContent:'space-between' }}>
                            <img src={copy}></img>
                            <img src={whatsapp}></img>
                            <img src={telegram}></img>
                            <img src={xIcon}></img>
                            <img src={instagram}></img>
                        </Box>

                    </Box>
                </Box>
            </Modal>
        </Box >
    )
}

export default MyEvents