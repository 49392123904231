import { Box, Typography, Modal, Checkbox, styled } from '@mui/material'
import { Celebration, Cake, ContentPaste, LocalCafe, Close } from "@mui/icons-material";
import ButtonPurple from '../buttons/buttonPurple';
import { useState } from 'react';
import ButtonBorder from '../buttons/buttonBorder';


const EventCard = ({ bgColor }) => {
    const [openModal, setOpenModal] = useState(false)

    const handleOpenModal = () => {
        setOpenModal(true)
    }

    const EventDetales = styled(Box)(({ theme }) => ({
        width: '100%',
        display: 'flex',
        // flexDirection: 'column',
        maxHeight: '70vh',
        overflow: 'auto',
        paddingRight: '4px',
        '&::-webkit-scrollbar': {
            width: '3px',
            background: 'transparent',
            border: 'none',
            borderRadius: '20px !important'
        },
        '&::-webkit-scrollbar-thumb': {
            width: '3px',
            height: '3px',
            background: '#9747ff',
            border: '0.5px solid #9747ff',
            borderRadius: '20px !important'
        },
        '&::-webkit-scrollbar-button': {
            width: '1px',
            height: '1px',
            background: '#9747ff',
            border: '0.5px solid #C6BAC5',
            borderRadius: '50% !important'
        },
        "@media (max-width: 600px)": {
            maxHeight: '100%',
        },
    }))


    return (
        <Box
            sx={(theme) => ({
                minWidth: { xs: '350px', md: '450px' }, aspectRatio: '2/1', bgColor: 'black', borderRadius: '24px', backgroundColor: bgColor,
                boxShadow: theme.palette.primary.boxShadow, display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'relative',
                overflow: 'hidden',
            })}

        >
            {
                bgColor == '#FFFFFF' ? <Celebration sx={{ fontSize: { xs: '75px', md: '100px' } }} /> : <Cake sx={{ fontSize: { xs: '75px', md: '100px' } }} />
            }

            <Box sx={(theme) => ({
                position: 'absolute', top: '0', left: '0', width: '100%', height: '100%',
                backdropFilter: 'blur(3px)', padding: '12px', boxSizing: 'border-box', display: 'flex',
                flexDirection: 'column', justifyContent: 'space-between', alignItems: 'flex-end'
            })}>
                <Box sx={(theme) => ({
                    width: '100%', backgroundColor: 'white', height: '55px',
                    boxShadow: theme.palette.primary.boxShadow, borderRadius: '50px', display: 'flex',
                    padding: '8px', alignItems: 'center', boxSizing: 'border-box', justifyContent: 'space-between'
                })}>
                    <Box sx={{ display: 'flex', alignItems: 'center', boxSizing: 'border-box' }}>
                        <Box sx={(theme) => ({
                            width: '46px',
                            background: `url(https://reqres.in/img/faces/${bgColor == '#FFFFFF' ? 1 : 2}-image.jpg)`,
                            backgroundSize: '46px 46px',
                            height: '46px',
                            borderRadius: '50%'
                        })}></Box>
                        <Typography sx={{ pl: '10px', fontSize: { xs: '14px', sm: '16px' } }}>Negin</Typography>
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', boxSizing: 'border-box' }}>
                        <Typography sx={{ pl: '10px', color: '#787878', fontSize: '14px', display: { xs: 'none', sm: 'block' } }}>Entrance Fee: <b style={{ color: '#000' }}>Free</b></Typography>
                        <Typography sx={{ pl: '10px', color: '#787878', fontSize: '14px' }}><b style={{ color: '#000' }}>My Birthday’s Location Survey</b></Typography>
                    </Box>

                    {bgColor == '#FFFFFF' ? <ContentPaste sx={{ color: '#4C00AF', fontSize: { xs: '24px', sm: '32px' } }} /> : <LocalCafe sx={{ color: '#4C00AF', fontSize: { xs: '24px', sm: '32px' } }} />}
                </Box>
                <ButtonPurple onClick={handleOpenModal} text={'Details & Join'} />

                <Modal
                    open={openModal}
                    onClose={() => setOpenModal(false)}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    disableScrollLock={true}

                >
                    <Box sx={(theme) => ({
                        width: '100%',
                        height: '100%',
                        display: 'flex', alignItems: 'center', justifyContent: 'center',
                        backdropFilter: 'blur(10px)', pointerEvents: 'none'
                    })}>
                        <Box sx={{
                            borderRadius: { xs: '0', sm: '24px' },
                            width: { xs: '100%', sm: '800px' },
                            height: { xs: '100%', sm: 'auto' },
                            backgroundColor: 'secondary.bg',
                            boxShadow: 'primary.boxShadow',
                            boxSizing: 'border-box',
                            display: 'flex',
                            flexDirection: 'column',
                            padding: '30px',
                            alignItems: 'center',
                            pointerEvents: 'all',
                            gap: '24px',
                        }}>
                            <div style={{ width: '100%', display: 'flex', justifyContent: 'end' }} onClick={() => setOpenModal(false)}>
                                <Close sx={{ cursor: 'pointer' }} />
                            </div>
                            <Typography sx={{ fontSize: '22px', textAlign: 'center', width: '100%', display: 'flex', alignItems: 'center', gap: '4px', justifyContent: 'center' }}>
                                <ContentPaste sx={{ color: '#4C00AF', fontSize: '34px' }} />
                                sara-asadi’s Event
                            </Typography>

                            <EventDetales>
                                <Box sx={{ display: 'flex', flexDirection: 'column', width: '50%', boxSizing: 'border-box', px: '10px', alignItems: 'center', gap: '30px' }}>
                                    <Typography sx={{ fontSize: '18px', fontWeight: '600' }}>Subject Of event</Typography>
                                    <Box sx={{ width: '135px', aspectRatio: '4/7', backgroundColor: 'primary.main', borderRadius: '8px' }}></Box>
                                    <Typography sx={{ fontSize: '14px', color: 'darkGray' }}>Description Of Event Description Of Event Description Of Event Description Of Event Description Of Event Description Of Event</Typography>
                                </Box>
                                <Box sx={{ display: 'flex', flexDirection: 'column', width: '50%', boxSizing: 'border-box', px: '10px', gap: '14px' }}>
                                    <Typography sx={{ fontSize: '14px', textAlign: 'center', width: 'full', }}>Proposal Question Proposal QuestionProposal QuestionProposal QuestionProposal Question</Typography>
                                    <Box sx={{ border: 'solid 1px #dedede', borderRadius: '16px', boxSizing: 'border-box', padding: '16px' }}>
                                        <Box sx={{ display: 'flex', alignItems: 'center', fontSize: '14px' }}>
                                            <Checkbox />
                                            <Typography sx={{ display: 'flex', fontSize: '12px', cursor: 'pointer', color: 'primary.darkGray' }}>
                                                options 01
                                            </Typography>
                                        </Box>
                                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                            <Checkbox />
                                            <Typography sx={{ display: 'flex', fontSize: '12px', cursor: 'pointer', color: 'primary.darkGray' }}>
                                                options 02
                                            </Typography>
                                        </Box>
                                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                            <Checkbox />
                                            <Typography sx={{ display: 'flex', fontSize: '12px', cursor: 'pointer', color: 'primary.darkGray' }}>
                                                options 03
                                            </Typography>
                                        </Box>
                                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                            <Checkbox />
                                            <Typography sx={{ display: 'flex', fontSize: '12px', cursor: 'pointer', color: 'primary.darkGray' }}>
                                                options 04
                                            </Typography>
                                        </Box>
                                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                            <Checkbox />
                                            <Typography sx={{ display: 'flex', fontSize: '12px', cursor: 'pointer', color: 'primary.darkGray' }}>
                                                options 05
                                            </Typography>
                                        </Box>
                                    </Box>
                                    <Box sx={{ border: 'solid 1px #dedede', borderRadius: '16px', boxSizing: 'border-box', padding: '16px' }}>
                                        <Typography sx={{ fontSize: '14px' }}>Comment</Typography>
                                        <Typography sx={{ fontSize: '14px', color: 'primary.darkGray' }}>Comment Comment Comment Comment Comment Comment Comment Comment Comment Comment Comment Comment Comment Comment Comment Comment Comment Comment Comment Comment Comment Comment Comment Comment Comment Comment Comment Comment Comment Comment Comment Comment Comment Comment Comment Comment Comment </Typography>

                                    </Box>
                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                        <Checkbox />
                                        <Typography sx={{ display: 'flex', fontSize: '12px', cursor: 'pointer', color: 'primary.darkGray' }}>
                                            Sending response anonymously
                                        </Typography>
                                    </Box>
                                    <Box sx={{ display: 'flex', width: 'full', gap: '10px', justifyContent: 'center' }}>
                                        <ButtonBorder text={'Exit'} />
                                        <ButtonPurple w={'100%'} text={'Send'} />
                                    </Box>
                                </Box>
                            </EventDetales>

                        </Box>
                    </Box>
                </Modal>
            </Box>
        </Box>
    )
}

export default EventCard