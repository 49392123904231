import { Box, Checkbox, FormControlLabel, FormGroup, Typography, styled, Modal } from '@mui/material'
import { useEffect, useRef, useState } from 'react'
import EventSlider from './eventSlider';
import EventCard from './eventCard';
import { BulletFiltering, IosSwitch, MyInput, SelectInput, SubTab, SubTabs } from '../utils';
import { Coin, UserSearch } from 'iconsax-react';
import { BrandingWatermark, Close, CommentOutlined, Description } from '@mui/icons-material';
import ButtonPurple from '../buttons/buttonPurple';
import ButtonOutline from '../buttons/buttonOutline';
import ButtonBorder from '../buttons/buttonBorder';
import CreateChatroom from './createChatroom';
import CreateSocial from './createSocial';
import CreateProposal from './createProposal';

const Container = styled(Box)(({ theme }) => ({
    boxSizing: 'border-box',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: theme.palette.secondary.bg,
    padding: '12px 18px 18px 18px',
    gap: '40px',
    borderRadius: '18px', transition: '500ms ease',
    boxShadow: theme.palette.primary.boxShadow,
    "@media (max-width: 600px)": {
        padding: '8px',
        gap: '20px',
    },
    marginBottom: '30px'
}))
const FlexRow = styled(Box)(({ theme }) => ({
    display: 'flex', width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
}))
const FlexColumn = styled(Box)(({ theme }) => ({
    display: 'flex', flexDirection: 'column',
    justifyContent: 'space-between', width: '100%',
    alignItems: 'center',
}))

const TermsAndConditions = styled(Box)(({ theme }) => ({
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    maxHeight: '70vh',
    overflow: 'auto',
    paddingRight: '4px',
    '&::-webkit-scrollbar': {
        width: '3px',
        background: 'transparent',
        border: 'none',
        borderRadius: '20px !important'
    },
    '&::-webkit-scrollbar-thumb': {
        width: '3px',
        height: '3px',
        background: '#9747ff',
        border: '0.5px solid #9747ff',
        borderRadius: '20px !important'
    },
    '&::-webkit-scrollbar-button': {
        width: '1px',
        height: '1px',
        background: '#9747ff',
        border: '0.5px solid #C6BAC5',
        borderRadius: '50% !important'
    },
    "@media (max-width: 600px)": {
        maxHeight: '100%',
    },
}))


const CreateEvent = () => {
    const [activeTab, setActiveTab] = useState('chatroom')
    const [entranceFeeChecked, setEntranceFeeChecked] = useState(false)
    const [entranceFeeInvitedChecked, setEntranceFeeInvitedChecked] = useState(false)
    const [termsChecked, setTermsChecked] = useState(false)
    const [termsOpen, setTermsOpen] = useState(false)

    useEffect(() => {
        window.document.getElementById("scrollable-events-panel-inside").scrollTo(0, 0);

    }, [])

    const openTermsModal = (e) => {
        e.stopPropagation()
        setTermsOpen(true)
    }
    return (
        <Box sx={{
            width: '100%',
            maxWidth: '1000px',
            display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '24px', p: '0', mb: '10px', mt: '10px'
        }}>
            <SubTabs jc={'center'} mb={'24px'}>
                <SubTab id={"chatroom"}
                    onClick={(e) => {
                        setActiveTab(e.target.id)
                        window.document.getElementById("scrollable-events-panel-inside").scrollTo(0, 0);
                    }}
                    text={'Chatroom'}
                    selected={activeTab == 'chatroom'} />

                <SubTab id={"social-gathering"}
                    onClick={(e) => {
                        setActiveTab(e.target.id)
                        window.document.getElementById("scrollable-events-panel-inside").scrollTo(0, 0);
                    }}
                    text={'Social Gathering'}
                    selected={activeTab == 'social-gathering'} />
                <SubTab id={"proposal"}
                    onClick={(e) => {
                        setActiveTab(e.target.id)
                        window.document.getElementById("scrollable-events-panel-inside").scrollTo(0, 0);
                    }}
                    text={'Proposal'} selected={activeTab == 'proposal'} />
            </SubTabs>
            {
                activeTab == 'chatroom' &&
                <CreateChatroom openTermsModal={openTermsModal} />
            }
            {
                activeTab == 'social-gathering' &&
                <CreateSocial openTermsModal={openTermsModal} />
            }
            {
                activeTab == 'proposal' &&
                <CreateProposal />
            }

            <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '12px' }}>
                <Typography sx={{ color: 'primary.text', textAlign: 'left', fontSize: '22px', lineHeight: 'normal', fontWeight: '400', width: '100%' }}>
                    Recommended Events For You
                </Typography>
            </Box>
            <EventSlider>
                <EventCard bgColor={'#FFFFFF'} />
                <EventCard bgColor={'#C3FBC6'} />
                <EventCard bgColor={'#FFFFFF'} />
                <EventCard bgColor={'#C3FBC6'} />
            </EventSlider>

            <Modal
                open={termsOpen}
                onClose={() => setTermsOpen(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                disableScrollLock={true}

            >
                <Box sx={(theme) => ({
                    width: '100%',
                    height: '100%',
                    display: 'flex', alignItems: 'center', justifyContent: 'center',
                    backdropFilter: 'blur(10px)', pointerEvents: 'none'
                })}>
                    <Box sx={{
                        borderRadius: { xs: '0', sm: '24px' },
                        width: { xs: '100%', sm: '480px' },
                        height: { xs: '100%', sm: 'auto' },
                        backgroundColor: 'secondary.bg',
                        boxShadow: 'primary.boxShadow',
                        boxSizing: 'border-box',
                        display: 'flex',
                        flexDirection: 'column',
                        padding: '30px',
                        alignItems: 'end',
                        pointerEvents: 'all',
                        gap: '12px',
                    }}>
                        <div onClick={() => setTermsOpen(false)}>
                            <Close sx={{ cursor: 'pointer' }} />
                        </div>
                        <Typography sx={{ color: 'primary.text', fontSize: '16px', textAlign: 'center', width: '100%', fontWeight: '600' }}>
                            YouWho Event Terms & Conditions
                        </Typography>
                        <TermsAndConditions>
                            <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'start', pt: '12px', textAlign: 'justify' }}>
                                <Typography sx={{ color: 'primary.darkGray', fontSize: '14px', textAlign: 'left', width: '100%', fontWeight: '600' }}>
                                    General Rules
                                </Typography>
                                <ul style={{ color: '#787878', fontFamily: 'inter', fontSize: '12px', display: 'flex', flexDirection: 'column', gap: '12px' }}>
                                    <li style={{ lineHeight: '1.5' }}>If the entry fee for any event is free, there will be no cost for the event organizer either.</li>
                                    <li style={{ lineHeight: '1.5' }}>If the entry fee for any event is not free, YouWho will charge a five percent commission on the total entry fee amount.</li>
                                    <li style={{ lineHeight: '1.5' }}>The event organizer will have the option to save the event content as a PDF for a duration of one week.</li>
                                </ul>
                            </Box>
                            <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'start', pt: '12px' }}>
                                <Typography sx={{ color: 'primary.darkGray', fontSize: '14px', textAlign: 'left', width: '100%', fontWeight: '600' }}>
                                    Chatroom Rules
                                </Typography>
                                <ul style={{ color: '#787878', fontFamily: 'inter', fontSize: '12px', display: 'flex', flexDirection: 'column', gap: '12px' }}>
                                    <li style={{ lineHeight: '1.5' }}>If at least one-third of the entered population reports the chat room, the chat room will be closed as soon as the required number of report requests is reached. The event organizer will not be allowed to hold any events for one month.</li>
                                </ul>
                            </Box>
                            <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'start', pt: '12px' }}>
                                <Typography sx={{ color: 'primary.darkGray', fontSize: '14px', textAlign: 'left', width: '100%', fontWeight: '600' }}>
                                    Proposal Rules
                                </Typography>
                                <ul style={{ color: '#787878', fontFamily: 'inter', fontSize: '12px', display: 'flex', flexDirection: 'column', gap: '12px' }}>
                                    <li style={{ lineHeight: '1.5' }}>If a PROPOSAL is organized for the advancement of YouWho and includes criticisms and suggestions to YouWho, YouWho have permission to read questions and answers.</li>
                                    <li style={{ lineHeight: '1.5' }}>If a PROPOSAL is organized for the advancement of YouWho and includes criticisms and suggestions to YouWho, and if it is organized for free, YouWho will gift one hundred tokens to the organizer as a gesture of appreciation for their loyal users.</li>
                                    <li style={{ lineHeight: '1.5' }}>If a survey is conducted for the advancement of YouWho and includes criticisms and suggestions for YouWho, and if it has an entry fee, YouWho will not charge any fee from the organizer as a gesture of appreciation for their loyal users.</li>
                                </ul>
                            </Box>
                        </TermsAndConditions>
                    </Box>
                </Box>
            </Modal>
        </Box >
    )
}

export default CreateEvent