import { Box, Typography, styled } from '@mui/material'
import { useRef } from 'react';
import { useDraggable } from "react-use-draggable-scroll";

const Container = styled(Box)(({ theme }) => ({
    boxSizing: 'border-box',
    width: '100%',
    display: 'flex',
    flexWrap: 'nowrap',
    gap: '12px',
    overflowX: 'auto',
    padding:'5px 5px 12px 5px',
    marginBottom:'16px',
    '&::-webkit-scrollbar': {
        height: '4px',
        width: '3px',
        background: 'transparent',
        border: 'none',
        borderRadius: '20px !important'
    },
    '&::-webkit-scrollbar-thumb': {
        width: '3px',
        height: '3px',
        background: '#9747ff',
        border: '0.5px solid #9747ff',
        borderRadius: '20px !important'
    },
    '&::-webkit-scrollbar-button': {
        display: 'none',
        width: '1px',
        height: '1px',
        background: '#9747ff',
        border: '0.5px solid #C6BAC5',
        borderRadius: '50% !important'
    },
    "@media (max-width: 600px)": {
        '&::-webkit-scrollbar': {
            display: 'none',
        },
    },
}))
const EventSlider = ({children}) => {
    const ref = useRef();
    const { events } = useDraggable(ref);

    return (
        <Container {...events} ref={ref}>
          {children}
        </Container>
    )
}

export default EventSlider