import styled from "@emotion/styled";
import { Box, CircularProgress, Typography } from "@mui/material";
import Navbar from "../components/Navbar";
import { BG_URL, PUBLIC_URL } from "../utils/utils";
import imgbg from '../assets/Y-HUG-COIN.svg'
import { blogContents, shorten } from "../components/utils";
import Footer from "../components/Footer";
import { useParams } from "react-router";
import { useEffect, useState } from "react";
const Wrapper = styled(Box)(({ theme }) => ({
    width: '100%',
    maxWidth: '1440px',
    margin: '0 auto',
    "@media (max-width: 1440px)": {
        width: '100%',
    },
}))
const TopPic = styled(Box)(({ theme }) => ({
    width: '100%', height: '700px', borderRadius: '28px', position: 'relative',
    backgroundSize: 'cover', backgroundRepeat: 'no-repeat',
    //  filter: 'blur(1px)',
    backgroundPosition: 'center',

    "@media (max-width: 600px)": {
        height: '330px', borderRadius: '16px',
    },

}))
const TitleWrapper = styled(Box)(({ theme }) => ({
    boxSizing: 'border-box', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center',
    gap: '32px', backdropFilter: 'blur(5px)',
    width: 'max-content', padding: '24px 32px', borderRadius: '28px 28px 0px 0px', position: 'absolute',
    backgroundColor: theme.palette.primary.bgOp, bottom: 0, left: '50%', transform: 'translate(-50%, 0)',
    "@media (max-width: 600px)": {
        height: '100%', width: '100%', borderRadius: '16px',
    },

}))

const BlogSingle = ({ theme, switchTheme }) => {
    const params = useParams()
    const id = params.id
    const blogg = blogContents.find(blg => blg.id == id)
    const [blog, setBlog] = useState(undefined)
    const [blogContent, setBlogContent] = useState(undefined)
    const getBlog = async () => {
        let request = await fetch(`https://strapi.admin.youwho.club/api/posts/${id}?populate=*`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer 030e28b8bd12af97987773621b00a78eb16dd61dd02b6ff82313fba6cab94fc8ce6f1753632584f1951f8e51ead0c205d6fa98363234971db9da7431480275d29d1fc0f0a4334ec587a3fc30a9a8648cf82cdee81c44e267908c6356c0a28a8e551b246a152905a5a6da9c8bec4c1f8d2b363a6da147f4425e30fd5fc7e53864`,
            }
        })

        let response = await request.json()
        console.log(response)
        setBlog(response.data.attributes)
        setBlogContent(response.data.attributes.content)
    }
    useEffect(() => {
        getBlog()
    }, [])
    useEffect(() => {
        if (blog && blogContent) {
            const bgcntn = document.getElementById("blogContent");
            bgcntn.innerHTML = blogContent

        }
    }, [blog, blogContent])



    return (
        <Box sx={{
            width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', bgcolor: 'primary.bg'
        }}>
            <Navbar navbarType={'radius'} theme={theme} switchTheme={switchTheme} />
            <Wrapper>
                {blog ?
                    <Box sx={{
                        width: '100%',
                        boxSizing: 'border-box', padding: { xs: '32px 4px 0px 4px', md: '38px 38px 0px 38px' },
                        display: 'flex', flexDirection: 'column', justifyContent: 'center', bgcolor: 'primary.bg',
                        gap: { xs: '50px', md: '100px' }
                    }}>
                        <TopPic sx={{
                            overflow: 'hidden',
                            backgroundImage: localStorage.getItem('theme') == 'light' ? `url('https://strapi.admin.youwho.club${blog.coverlight.data.attributes.url}')` : `url('https://strapi.admin.youwho.club${blog.coverdark.data.attributes.url}')`,
                            // backgroundImage: localStorage.getItem('theme') == 'light' ? BG_URL(PUBLIC_URL(`${blog.imageLight}`)) : BG_URL(PUBLIC_URL(`${blog.imageDark}`)),
                            bgcolor: 'primary.gray',
                            display: 'flex', alignItems: 'center',
                        }}>
                            <TitleWrapper>
                                <Typography sx={{ color: 'white', fontSize: { xs: '22px', sm: '32px', md: '64px' } }}>
                                    {blog.title}
                                </Typography>
                                <Typography sx={{
                                    display: { xs: 'none', md: 'block' },
                                    color: 'white', fontSize: { xs: '18px', sm: '22px', md: '32px' }
                                }}>
                                    {shorten(blog.subtitle, 45)}
                                </Typography>
                                <Typography sx={{
                                    display: { xs: 'none', sm: 'block', md: 'none' },
                                    color: 'white', fontSize: { xs: '18px', sm: '22px', md: '32px' }
                                }}>
                                    {shorten(blog.subtitle, 50)}
                                </Typography>
                                <Typography sx={{
                                    display: { xs: 'block', sm: 'none' },
                                    color: 'white', fontSize: { xs: '18px', sm: '22px', md: '32px' }
                                }}>
                                    {blog.subtitle}
                                </Typography>
                            </TitleWrapper>
                        </TopPic>
                        <Typography id="blogContent" sx={{
                            color: 'primary.text', width: '100%', textAlign: 'justify', fontFamily: 'Inter',
                            textTransform: 'capitalize'
                        }}>
                            {/* {blog.content} */}
                        </Typography>
                        <Footer />
                    </Box>
                    :
                    <Box sx={{
                        width: '100%', height: '100vh',
                        boxSizing: 'border-box', padding: { xs: '32px 4px 0px 4px', md: '38px 38px 0px 38px' },
                        display: 'flex', alignItems: 'center', justifyContent: 'center', bgcolor: 'primary.bg',
                        gap: { xs: '50px', md: '100px' }
                    }}>
                        <CircularProgress />
                    </Box>
                }
            </Wrapper>

        </Box>
    );
}

export default BlogSingle;