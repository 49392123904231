import { useSelector } from "react-redux";
import PanelLayout from "../components/PanelLayout";
import { Box, Modal, Typography } from "@mui/material";
import ButtonOutline from "../components/buttons/buttonOutline";
import { useNavigate } from "react-router";
import CreateWallet from "../components/user/wallet/createWallet";
import VerifyPhone from "../components/user/auth/verifyPhone";
import Wallet from "../components/user/wallet/wallet";
import { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { Close } from "@mui/icons-material";
import VerifyPhoneModal from "../components/user/auth/verifyPhoneModal";
import { TickSquare } from "iconsax-react";
import { Link } from "react-router-dom";
const FlexRow = styled(Box)(({ theme }) => ({
    // width: '250px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    color: theme.palette.primary.light,
}))

const WalletPage = ({ switchTheme, theme }) => {
    const globalUser = useSelector(state => state.userReducer)
    const navigate = useNavigate()
    const [privateKey, setPrivateKey] = useState(undefined)
    // const [openModal, setOpenModal] = useState(false)
    // if phone verification is necessary ===>
    // useEffect(() => {
    //     if (globalUser.isLoggedIn) {
    //         if (!globalUser.isPhoneVerified) {
    //             setOpenModal(true)
    //         }
    //     }
    // }, [globalUser.isLoggedIn, globalUser.YouWhoID, globalUser.isPhoneVerified])
    const [openModal, setOpenModal] = useState(false)
    useEffect(() => {
        if (privateKey)
            setOpenModal(true)
    }, [privateKey])
    const [keyCopied, setKeyCopied] = useState(false)
    const [idCopied, setIdCopied] = useState(false)
    const copyToClipBoard = async (textToCopy) => {
        try {
            await navigator.clipboard.writeText(textToCopy);
            setKeyCopied('Copied!');
        } catch (err) {
            setKeyCopied('Failed to copy!');
        }
    };
    const copyidToClipBoard = async (textToCopy) => {
        try {
            await navigator.clipboard.writeText(textToCopy);
            setIdCopied('Copied!');
        } catch (err) {
            setIdCopied('Failed to copy!');
        }
    };


    return (
        <PanelLayout switchTheme={switchTheme} theme={theme} id={"scrollable-wallet-panel"}>
            {!globalUser.isLoggedIn ?
                <Box
                    sx={{
                        ml: { xs: 'none', sm: '50px' },
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%',
                        height: 'calc(100vh - 55px)',
                        gap: { xs: '22px', md: '30px' }, color: 'primary.text', justifyContent: 'center', alignItems: 'center',
                        boxSizing: 'border-box', padding: '20px 15px 40px'
                    }}>
                    <p> you are not logged in</p>
                    <ButtonOutline text={'start'} onClick={() => navigate('/auth')} />
                </Box> :
                <>
                    {/* {globalUser.isPhoneVerified ? */}
                    <>{globalUser.YouWhoID ?
                        <Wallet privateKey={privateKey} /> :
                        <CreateWallet setPvKey={setPrivateKey} />
                    }</>

                    {/* // if phone verification is necessary ===> */}

                    {/* :
                        <>
                            <Box
                                sx={{
                                    ml: { xs: 'none', sm: '80px' }, color: 'primary.text', justifyContent: 'center', alignItems: 'center',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    width: '100%',
                                    height: 'calc(100vh - 55px)',
                                    gap: { xs: '22px', md: '30px' },
                                    boxSizing: 'border-box', padding: '20px 15px 40px'
                                }}>
                                <p>to create a wallet , you have to verify your phone first</p>
                                <ButtonOutline text={'start'} onClick={() => setOpenModal(true)} />
                            </Box>

                            <VerifyPhoneModal openModal={openModal} setOpenModal={setOpenModal} />
                        </>
                    } */}
                </>
            }


            <Modal
                open={openModal}
                onClose={() => {
                    setOpenModal(false)
                }}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                disableScrollLock={true}
            >
                <Box sx={(theme) => ({
                    width: '100%',
                    height: '100%',
                    display: 'flex', alignItems: 'center', justifyContent: 'center',
                    backdropFilter: 'blur(10px)'
                })}>
                    <Box sx={(theme) => ({
                        borderRadius: { xs: '0', sm: '24px' },
                        width: '100%', maxWidth: '600px', height: { xs: '100%', sm: 'auto' },
                        backgroundColor: 'secondary.bg', boxShadow: theme.palette.primary.boxShadow, boxSizing: 'border-box',
                        display: 'flex', flexDirection: 'column',
                        padding: '30px', alignItems: 'center'
                    })}>
                        <FlexRow sx={{ justifyContent: 'end !important', width: '100%' }}>
                            <Box sx={{ padding: '10px' }}>
                                <Close onClick={() => setOpenModal(false)}
                                    sx={{ cursor: 'pointer', fontSize: '24px' }} />
                            </Box>
                        </FlexRow>
                        <Box sx={{
                            display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center',
                            gap: '20px'
                        }}>
                            {/* <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', color: "primary.darkGray" }}> */}
                            <Box sx={{
                                display: 'flex', flexDirection: 'column',
                                alignItems: 'center', justifyContent: 'center', color: 'primary.text'
                            }}>
                                <Typography sx={{
                                    color: 'primary.text', textTransform: 'capitalize', textAlign: 'center'
                                }}>
                                    your YouWho id :
                                </Typography>
                                <span
                                    style={{ fontSize: '13px', color: '#BEA2C5', cursor: 'pointer' }}
                                    onClick={() => copyidToClipBoard(globalUser.YouWhoID)}> {globalUser.YouWhoID}
                                </span>
                                <TickSquare style={{ display: idCopied ? 'block' : 'none', color: 'green' }} />
                            </Box>
                            <Box sx={{ color: 'primary.text', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                                <Typography sx={{
                                    color: 'primary.text', textTransform: 'capitalize', textAlign: 'center'
                                }}>
                                    your private key :
                                </Typography>
                                <span style={{ fontSize: { xs: '8px', sm: '13px' }, color: '#BEA2C5', cursor: 'pointer' }}
                                    onClick={() => copyToClipBoard(privateKey)}>
                                    {privateKey}
                                </span>
                                <TickSquare style={{ display: keyCopied ? 'block' : 'none', color: 'green' }} />
                            </Box>

                            <Box sx={{ color: 'primary.error', textTransform: 'capitalize' }}>
                                please save this information somewhere safe and private!
                            </Box>
                            <Box sx={{
                                whiteSpace: 'pre-line', width: '100%',
                                color: 'primary.text', textTransform: 'capitalize', textAlign: 'center'
                            }}>
                                you must have your private key to be able to use YouWho features . losing it will cause you to lose all of your data and tokens
                            </Box>
                            <ButtonOutline onClick={() => setOpenModal(false)} text={'Go To Wallet'} />
                            {/* </Box> */}
                        </Box>

                    </Box>
                </Box>
            </Modal>

        </PanelLayout>

    );
}

export default WalletPage;