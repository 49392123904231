import { Box, Typography, styled } from '@mui/material'
import { useEffect, useRef, useState } from 'react'
import EventSlider from './eventSlider';
import EventCard from './eventCard';
import { BulletFiltering } from '../utils';

const Container = styled(Box)(({ theme }) => ({
    boxSizing: 'border-box',
    width: '100%',
    display: 'flex',
    flexWrap: 'nowrap',
    gap: '12px',
    overflowX: 'auto',
    padding: '8px 0px',
    '&::-webkit-scrollbar': {
        height: '4px',
        width: '3px',
        background: 'transparent',
        border: 'none',
        borderRadius: '20px !important'
    },
    '&::-webkit-scrollbar-thumb': {
        width: '3px',
        height: '3px',
        background: '#9747ff',
        border: '0.5px solid #9747ff',
        borderRadius: '20px !important'
    },
    '&::-webkit-scrollbar-button': {
        display: 'none',
        width: '1px',
        height: '1px',
        background: '#9747ff',
        border: '0.5px solid #C6BAC5',
        borderRadius: '50% !important'
    },
    "@media (max-width: 600px)": {
        '&::-webkit-scrollbar': {
            display: 'none',
        },
    },
}))
const FilterSelectionBox = styled(Box)(({ theme }) => ({
    display: 'flex', boxSizing: 'border-box',
    flexDirection: 'row',
    alignItems: 'center',
    cursor: 'pointer',
    justifyContent: 'start',
    border: '1px solid #DEDEDE',
    borderRadius: '18px',
    overflow: 'hidden',
    // height: '20px',
    color: theme.palette.primary.text,
    backgroundColor: theme.palette.secondary.bg,
    width: '100%'
}))

const EventsHome = ({ filterOpen }) => {
    const [pricefilterValue, setPriceFilterValue] = useState('all')
    const [stateFilterValue, setStateFilterValue] = useState('all')
    const [sortingFilterValue, setSortingFilterValue] = useState('sort-by-ascending-(time)')
    const priceFilterOptions = ['all', 'free-ones', 'non-free-ones']
    const statusFilterOptions = ['all', 'finished', 'pending', 'rejecteds']
    const SortingFilterOptions = ['sort-by-ascending-(time)', 'sort-by-descending-(time)']

    useEffect(() => {
        window.document.getElementById("scrollable-events-panel-inside").scrollTo(0, 0);

    }, [])

    return (
        <Box sx={{
            width: '100%',
            maxWidth: '1000px',
            display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '24px', p: '0', mb: '10px', mt: '10px'
        }}>
            <Box sx={{ width:'100%',display: 'flex',flexDirection: 'column', alignItems: 'center',
            height: filterOpen ? '230px' : '0px' , transition: '0.3s'}}>
                <Box sx={{
                    width: { xs: '100%', sm: '80%', md: '70%' }, boxSizing: 'border-box',
                    overflow: 'hidden',
                    display: filterOpen ? 'flex' : 'none',
                    gap: { xs: '8px', md: '16px' }, flexDirection: 'column'
                }}>
                    <FilterSelectionBox sx={{ padding: '8px 16px', }}>
                        <span style={{ width: 'max-content', whiteSpace: 'nowrap', fontSize: '14px', paddingRight: '16px' }}>
                            Event Type:
                        </span>
                        <input
                            style={{
                                height: '20px',
                                backgroundColor: 'transparent', border: 'none', outline: 'none',
                                color: '#c2c2c2', width: '100%'
                            }}
                            onChange={() => { }}
                            placeholder='All Types Of Events'
                        />
                    </FilterSelectionBox>
                    <BulletFiltering setOption={setPriceFilterValue} options={priceFilterOptions} selected={pricefilterValue} fontSize={'14px'} width={'100%'} />
                    <BulletFiltering setOption={setStateFilterValue} options={statusFilterOptions} selected={stateFilterValue} fontSize={'14px'} width={'100%'} />
                    <BulletFiltering setOption={setSortingFilterValue} options={SortingFilterOptions} selected={sortingFilterValue} fontSize={'14px'} width={'100%'} />
                </Box>
            </Box>
            <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '12px' }}>
                <Typography sx={{ color: 'primary.text', textAlign: 'left', fontSize: '22px', lineHeight: 'normal', fontWeight: '400', width: '100%' }}>Events You Joined</Typography>
            </Box>
            <EventSlider>
                <EventCard bgColor={'#FFFFFF'} />
                <EventCard bgColor={'#C3FBC6'} />
                <EventCard bgColor={'#FFFFFF'} />
                <EventCard bgColor={'#C3FBC6'} />
            </EventSlider>
            <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '12px' }}>
                <Typography sx={{ color: 'primary.text', textAlign: 'left', fontSize: '22px', lineHeight: 'normal', fontWeight: '400', width: '100%' }}>Events You Invited</Typography>
            </Box>
            <EventSlider>
                <EventCard bgColor={'#FFFFFF'} />
                <EventCard bgColor={'#C3FBC6'} />
                <EventCard bgColor={'#FFFFFF'} />
                <EventCard bgColor={'#C3FBC6'} />
            </EventSlider>

        </Box>
    )
}

export default EventsHome