import { Box, Typography, Accordion, AccordionDetails, AccordionSummary, CircularProgress } from '@mui/material'
import { ExpandMore } from '@mui/icons-material';
import { API_CONFIG } from '../../config'
import { useSelector } from "react-redux";
import { useEffect, useState } from 'react'

const WithdrawMessages = () => {
    const globalUser = useSelector(state => state.userReducer)
    const [messages, setMessages] = useState(null)

    useEffect(() => {
        if (globalUser.token) {
            getWithdrawMessages()
        }

    }, [globalUser.token])

    const getWithdrawMessages = async () => {
        let request = await fetch(`${API_CONFIG.ADMIN_API_URL}/ticket/get/all/?from=0&to=100`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${globalUser.token}`,
                'Content-Type': 'application/json',
            }
        })

        let response = await request.json()
        if (response.status === 200) {
            setMessages(response.data.filter(message => message.title === 'withdraw'))
        } else {
            console.log(response)
        }
    }
    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%', gap: '20px' }}>
            <Typography sx={{ width: '100%', textAlign: 'center', fontSize: '22px', my: '24px' }}>Withdraw Messages</Typography>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '90%', maxWidth: '800px', gap: '18px' }}>
                {
                    messages ?
                        messages.map((message, index) => (
                            <Accordion sx={{ borderRadius: '12px !important', overflow: 'hidden', boxShadow: 'palette.primary.boxShadowLarge', width: '100%' }} key={message.id}>
                                <AccordionSummary
                                    expandIcon={<ExpandMore />}
                                    aria-controls="panel1-content"
                                    id="panel1-header"
                                >
                                    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%', justifyContent: 'space-between', gap: '16px' }}>
                                        <Typography sx={{ fontSize: '14px', fontFamily: 'inter', color: '#939599' }}>{index + 1}</Typography>
                                        <Typography sx={{ flexGrow: '1' }}>{message.cname}</Typography>
                                        <Typography sx={{ fontSize: '12px', fontFamily: 'inter', color: '#939599' }}>{message.created_at.slice(0, 10)}</Typography>
                                    </Box>
                                </AccordionSummary>
                                <AccordionDetails sx={{ display: 'flex', flexDirection: 'column', gap: '12px' }}>
                                    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%', justifyContent: 'space-between' }}>
                                        <Typography sx={{ fontSize: '13px' }}>User ID</Typography>
                                        <Typography sx={{ fontSize: '13px' }}>{message.user_id}</Typography>
                                    </Box>
                                    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%', justifyContent: 'space-between' }}>
                                        <Typography sx={{ fontSize: '13px' }}>Email</Typography>
                                        <Typography sx={{ fontSize: '13px' }}>{message.mail}</Typography>
                                    </Box>
                                    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%', justifyContent: 'space-between' }}>
                                        <Typography sx={{ fontSize: '13px' }}>Description</Typography>
                                        <Typography sx={{ fontSize: '13px' }}>{message.cdescription}</Typography>
                                    </Box>
                                </AccordionDetails>
                            </Accordion>
                        )) : <CircularProgress />
                }
            </Box>
        </Box>
    )
}

export default WithdrawMessages