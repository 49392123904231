import { Box, Typography, Accordion, AccordionDetails, AccordionSummary, CircularProgress } from '@mui/material'
import { ExpandMore } from '@mui/icons-material';
import { API_CONFIG } from '../../config'
import { useSelector } from "react-redux";
import { useEffect, useState } from 'react'

const UsersWithdrawals = () => {
    const globalUser = useSelector(state => state.userReducer)
    const [withdrawals, setWithdrawals] = useState(null)

    useEffect(() => {
        if (globalUser.token) {
            getUsersWithdrawals()
        }

    }, [globalUser.token])

    const getUsersWithdrawals = async () => {
        let request = await fetch(`${API_CONFIG.ADMIN_API_URL}/withdraw/get/?from=0&to=100`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${globalUser.token}`,
                'Content-Type': 'application/json',
            }
        })

        let response = await request.json()
        if (response.status === 200) {
            setWithdrawals(response.data)
        } else {
            console.log(response)
        }
    }

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%', gap: '20px' }}>
            <Typography sx={{ width: '100%', textAlign: 'center', fontSize: '22px', my: '24px' }}>Users Claims</Typography>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '90%', maxWidth: '800px', gap: '18px' }}>
                {
                    withdrawals ?
                        (withdrawals.length == 0) ?
                            <Typography sx={{ width: '100%', textAlign: 'center', fontSize: '14px', color: '#999' }}>There are no claims yet</Typography>
                            :
                            <>
                                {
                                    // "id": 1,
                                    // "deposit_id": 3,
                                    // "transfer_tx_hash": "0x40ab290df1ffe30727e159b39cbc7005fc2cce5baca3963fc78a97611ead351c",
                                    // "recipient_cid": "028d9b24dd680eee4e1861e23ff81c893f2e93324918c4cf11b7db895e9f4f9664",
                                    // "signature": "e7d6797893d133bd2abbb36a4a31d8d143c8c1137cbb1efd6315fff460909b4e47bb77608258379546130cb89df71e31b41882f8f201eca0154023c640bd0f881c",
                                    // "wat": "2024-05-03T14:28:46.012244"
                                    withdrawals.map((withdraw, index) => (
                                        <Accordion sx={{ borderRadius: '12px !important', overflow: 'hidden', boxShadow: 'palette.primary.boxShadowLarge', width: '100%' }} key={withdraw.id}>
                                            <AccordionSummary
                                                expandIcon={<ExpandMore />}
                                                aria-controls="panel1-content"
                                                id="panel1-header"
                                            >
                                                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%', justifyContent: 'space-between', gap: '24px' }}>
                                                    <Typography sx={{ fontSize: '14px', fontFamily: 'inter', color: '#939599' }}>{index + 1}</Typography>
                                                    <Typography sx={{ flexGrow: '1' }}>{withdraw.wat.slice(0, 10)}</Typography>
                                                    {/* <Typography sx={{ fontSize: '12px', fontFamily: 'inter', color: '#939599' }}>{withdraw.wat.slice(0, 10)}</Typography> */}
                                                </Box>
                                            </AccordionSummary>
                                            <AccordionDetails sx={{ display: 'flex', flexDirection: 'column', gap: '12px' }}>
                                                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%', justifyContent: 'space-between' }}>
                                                    <Typography sx={{ fontSize: '13px' }}>Deposit ID</Typography>
                                                    <Typography sx={{ fontSize: '13px' }}>{withdraw.deposit_id}</Typography>
                                                </Box>
                                                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%', justifyContent: 'space-between' }}>
                                                    <Typography sx={{ fontSize: '13px' }}>ID</Typography>
                                                    <Typography sx={{ fontSize: '13px' }}>{withdraw.id}</Typography>
                                                </Box>
                                                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%', justifyContent: 'space-between' }}>
                                                    <Typography sx={{ fontSize: '13px' }}>Recipient Cid</Typography>
                                                    <Typography sx={{ fontSize: '13px' }}>{withdraw.recipient_cid}</Typography>
                                                </Box>
                                            </AccordionDetails>
                                        </Accordion>
                                    ))
                                }
                            </>
                        : <CircularProgress />
                }
            </Box>
        </Box>
    )
}

export default UsersWithdrawals