import styled from "@emotion/styled";
import { Box, CircularProgress, Typography } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { API_CONFIG } from "../../config";
import { useDispatch, useSelector } from "react-redux";
import { BG_URL, PUBLIC_URL } from "../../utils/utils";
import ButtonPurple from "../buttons/buttonPurple";
import { useNavigate } from "react-router";
import ButtonOutline from "../buttons/buttonOutline";
import { toast } from "react-toastify";
import generateSignature from "../../utils/signatureUtils";
import Pagination from "../pagination";

const Gallery = styled(Box)(({ theme }) => ({
    width: '100%', boxSizing: 'border-box', alignItems: 'center', justifyContent: 'center',
    display: 'flex', gap: '10px', flexWrap: 'wrap', maxWidth: '900px', margin: '0 auto'
}))

const Room = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '16px',
    justifyContent: 'space-between',
    boxShadow: theme.palette.primary.boxShadow,
    borderRadius: '16px',
    height: '120px',
    width: '100%',
    //  maxWidth: '700px',
    color: theme.palette.primary.text,
    backgroundColor: theme.palette.secondary.bg,
    boxSizing: 'border-box', gap: '16px',
    "@media (max-width: 600px)": {
        flexDirection: 'column', height: 'auto', width: '180px', padding: '8px'
    },

}))

const FlexColumn = styled(Box)(({ theme }) => ({
    display: 'flex', flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'start',
    color: theme.palette.primary.text,
}))
const FlexRow = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    color: theme.palette.primary.text,
}))

const JoinedRooms = () => {
    const globalUser = useSelector(state => state.userReducer)
    const [roomsLoading, setRoomsLoading] = useState(true)
    const [err, setErr] = useState(undefined)
    const [rooms, setRooms] = useState([])
    const [from, setFrom] = useState(0)
    const [to, setTo] = useState(30)
    const [pgTabs, setPgTabs] = useState([1])
    const [selectedTab, setSelectedTab] = useState(1)
    const navigate = useNavigate()
    const toastId = useRef(null);
    const loading = () => {
        toastId.current = toast.loading("Please wait...")
        console.log(toastId)
    }
    const updateToast = (success, message) => {
        success ? toast.update(toastId.current, { render: message, type: "success", isLoading: false, autoClose: 3000 })
            : toast.update(toastId.current, { render: message, type: "error", isLoading: false, autoClose: 3000 })
    }

    const acceptGalleryInvitation = async (receiver, sender, galleryId) => {
        loading()
        let data = {
            owner_cid: sender,
            from_screen_cid: receiver,
            gal_id: galleryId
        }
        let { requestData } = generateSignature(globalUser.privateKey, data)
        console.log(requestData)
        let request = await fetch(`${API_CONFIG.AUTH_API_URL}/gallery/accept/invitation-request`, {
            method: 'POST',
            body: JSON.stringify(requestData),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${globalUser.token}`,
            }
        })
        let response = await request.json()
        console.log(response);
        if (!response.is_error) {
            updateToast(true, 'Invitation Accepted')
            getJoinedRooms()
        } else {
            updateToast(false, response.message)
        }
    }

    const getJoinedRooms = async () => {
        let request = await fetch(`${API_CONFIG.AUTH_API_URL}/gallery/get/all/i-invited/?from=${from}&to=${to}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${globalUser.token}`,
            }
        })
        let response = await request.json()
        console.log('invs', response)

        if (!response.is_error) {
            // setRooms(response.data)

            let roomsArr = response.data.slice((selectedTab - 1) * 15, ((selectedTab - 1) * 15) + 15)
            setRooms(roomsArr)
            if (response.data.length >= 15) {
                let pagTabs = []
                let tabNums = response.data.length / 15
                for (let i = 0; i < tabNums; i++) {
                    pagTabs.push(i + 1)
                }
                setPgTabs(pagTabs)
            } else {
                console.log('under 15 !')
            }
            setRoomsLoading(false)
        } else {
            if (response.status == 404) {
                setRooms([])
            } else {
                setRooms([])
                setErr(response.message)
            }
        }
    }
    useEffect(() => {
        getJoinedRooms()
    }, [])
    useEffect(() => {
        // setFrom((selectedTab - 1) * 15)
        setTo((((selectedTab - 1) * 15) + 15) + 15)
    }, [selectedTab])
    useEffect(() => {
        getJoinedRooms()
    }, [to])

    return (<Box sx={{
        display: 'flex', flexDirection: 'column', alignItems: 'center', gap: { xs: '16px', sm: '24px', md: '32px' }
    }}>
        {err ?
            <Typography sx={{ textAlign: 'center', textTransform: 'capitalize', color: 'primary.error' }}>{err}</Typography> :
            <Gallery>
                {roomsLoading ? <CircularProgress /> : <>
                    {rooms.length > 0 ? <>
                        {rooms.filter(r => r.is_accepted == true).map((room) => (
                            <Room>
                                <Box sx={{
                                    display: 'flex', flexDirection: { xs: 'column', sm: 'row' },
                                    justifyContent: 'center', alignItems: 'center',
                                    gap: { xs: '4px', sm: '10px', md: '16px' }
                                }}>
                                    <Box sx={{
                                        backgroundImage: `url('${API_CONFIG.API_URL}/${room.gallery_background}')`,
                                        backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'center'
                                        , width: { xs: '160px', sm: '100px' }, height: { xs: '160px', sm: '100px' }, borderRadius: '18px',
                                        backgroundColor: 'primary.bg'
                                    }}
                                    />
                                    <FlexColumn>
                                        <FlexRow>
                                            {/* <Typography sx={{ textTransform: 'capitalize', fontSize: { xs: '10px', sm: '12px' } }}>room name:</Typography> */}
                                            <Typography sx={{ textTransform: 'capitalize', fontSize: { xs: '16px', sm: '20px' }, fontWeight: 500 }}>{room.gal_name}</Typography>
                                        </FlexRow>
                                        <FlexRow>
                                            <Typography sx={{
                                                color: 'secondary.text',
                                                textTransform: 'capitalize', fontSize: { xs: '10px', sm: '12px' }
                                            }}>collections:&nbsp;</Typography>
                                            <Typography sx={{
                                                color: 'secondary.text',
                                                textTransform: 'capitalize', fontSize: { xs: '12px', sm: '14px' }
                                            }}>{room.collections}</Typography>
                                        </FlexRow>
                                        <FlexRow>
                                            <Typography sx={{
                                                color: 'secondary.text',
                                                textTransform: 'capitalize', fontSize: { xs: '10px', sm: '12px' }
                                            }}>joined people:&nbsp;</Typography>
                                            <Typography sx={{
                                                color: 'secondary.text',
                                                textTransform: 'capitalize', fontSize: { xs: '12px', sm: '14px' }
                                            }}>{room.invited_friends.length}</Typography>
                                        </FlexRow>
                                        <FlexRow>
                                            <Typography sx={{
                                                color: 'secondary.text',
                                                textTransform: 'capitalize', fontSize: { xs: '10px', sm: '12px' }
                                            }}>owner:&nbsp;</Typography>
                                            <Typography sx={{
                                                color: 'secondary.text',
                                                textTransform: 'capitalize', fontSize: { xs: '12px', sm: '14px' }
                                            }}>{room.owner_username}</Typography>
                                        </FlexRow>
                                    </FlexColumn>
                                </Box>
                                {room.is_accepted ?
                                    <ButtonPurple text={'Visit'} onClick={() => navigate(`/profile/${room.owner_username}?${room.id}#private-gallery-tab`)} />
                                    :
                                    <Box sx={{
                                        display: 'flex', flexDirection: { xs: 'column', sm: 'row' },
                                        justifyContent: 'center', alignItems: 'center',
                                        gap: { xs: '4px', sm: '8px' }
                                    }}>
                                        <ButtonOutline px={'8px'} text={'View In Gallery'} w={'max-content'} onClick={() => navigate(`/profile/${room.owner_username}#private-gallery-tab`)} />
                                        <ButtonPurple px={'8px'} text={'Accept Invite'} w={'max-content'}
                                            onClick={() => acceptGalleryInvitation(room.owner_screen_cid, globalUser.cid, room.id)}
                                        />
                                    </Box>
                                }
                            </Room>))}
                        {rooms.filter(r => r.is_accepted == false).length > 0 &&
                            <FlexRow sx={{ width: '100%', gap: '4px' }}>
                                <Typography sx={{ fontSize: '10px', textAlign: 'center', textTransform: 'capitalize', color: 'secondary.text' }}>Unaccepted Room Invitations</Typography>
                                <Box sx={{
                                    bgcolor: 'secondary.text', height: '1px', width: '100%', borderRadius: '10px'
                                }} />
                            </FlexRow>}
                        {rooms.filter(r => r.is_accepted == false).map((room) => (
                            <Room>
                                <Box sx={{
                                    display: 'flex', flexDirection: { xs: 'column', sm: 'row' },
                                    justifyContent: 'center', alignItems: 'center',
                                    gap: { xs: '4px', sm: '10px', md: '16px' }
                                }}>
                                    <Box sx={{
                                        backgroundImage: `url('${API_CONFIG.API_URL}/${room.gallery_background}')`,
                                        backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'center'
                                        , width: { xs: '160px', sm: '100px' }, height: { xs: '160px', sm: '100px' }, borderRadius: '18px',
                                        backgroundColor: 'primary.bg'
                                    }}
                                    />
                                    <FlexColumn>
                                        <FlexRow>
                                            {/* <Typography sx={{ textTransform: 'capitalize', fontSize: { xs: '10px', sm: '12px' } }}>room name:</Typography> */}
                                            <Typography sx={{ textTransform: 'capitalize', fontSize: { xs: '16px', sm: '20px' }, fontWeight: 500 }}>{room.gal_name}</Typography>
                                        </FlexRow>
                                        <FlexRow>
                                            <Typography sx={{
                                                color: 'secondary.text',
                                                textTransform: 'capitalize', fontSize: { xs: '10px', sm: '12px' }
                                            }}>collections:&nbsp;</Typography>
                                            <Typography sx={{
                                                color: 'secondary.text',
                                                textTransform: 'capitalize', fontSize: { xs: '12px', sm: '14px' }
                                            }}>{room.collections}</Typography>
                                        </FlexRow>
                                        <FlexRow>
                                            <Typography sx={{
                                                color: 'secondary.text',
                                                textTransform: 'capitalize', fontSize: { xs: '10px', sm: '12px' }
                                            }}>joined people:&nbsp;</Typography>
                                            <Typography sx={{
                                                color: 'secondary.text',
                                                textTransform: 'capitalize', fontSize: { xs: '12px', sm: '14px' }
                                            }}>{room.invited_friends.length}</Typography>
                                        </FlexRow>
                                        <FlexRow>
                                            <Typography sx={{
                                                color: 'secondary.text',
                                                textTransform: 'capitalize', fontSize: { xs: '10px', sm: '12px' }
                                            }}>owner:&nbsp;</Typography>
                                            <Typography sx={{
                                                color: 'secondary.text',
                                                textTransform: 'capitalize', fontSize: { xs: '12px', sm: '14px' }
                                            }}>{room.owner_username}</Typography>
                                        </FlexRow>
                                    </FlexColumn>
                                </Box>
                                {room.is_accepted ?
                                    <ButtonPurple text={'Visit'} onClick={() => navigate(`/profile/${room.owner_username}?${room.id}#private-gallery-tab`)} />
                                    :
                                    <Box sx={{
                                        display: 'flex', flexDirection: { xs: 'column', sm: 'row' },
                                        justifyContent: 'center', alignItems: 'center',
                                        gap: { xs: '4px', sm: '8px' }
                                    }}>
                                        <ButtonOutline px={'8px'} text={'View In Gallery'} w={'max-content'} onClick={() => navigate(`/profile/${room.owner_username}#private-gallery-tab`)} />
                                        <ButtonPurple px={'8px'} text={'Accept Invite'} w={'max-content'}
                                            onClick={() => acceptGalleryInvitation(room.owner_screen_cid, globalUser.cid, room.id)}
                                        />
                                    </Box>
                                }
                            </Room>))}
                    </> : <Typography sx={{ textAlign: 'center', textTransform: 'capitalize', color: 'primary.text' }}>No Room Found</Typography>}
                </>}
            </Gallery >
        }
        {rooms && rooms.length > 0 ?
            <Pagination tabs={pgTabs} selected={selectedTab} setSelectedTab={setSelectedTab} />
            : undefined}

    </Box>);
}

export default JoinedRooms;