import { Box, Checkbox, FormControlLabel, FormGroup, FormLabel, Radio, RadioGroup, Typography, duration, styled } from '@mui/material'
import { IosSwitch, MyInput, SelectInput, SubTab, SubTabs } from '../utils';
import { Coin, UserSearch } from 'iconsax-react';
import { BrandingWatermark, Description, } from '@mui/icons-material';
import ButtonPurple from '../buttons/buttonPurple';
import ButtonOutline from '../buttons/buttonOutline';
import { useState } from 'react';
import { FormControl } from '@mui/base';

const Container = styled(Box)(({ theme }) => ({
    boxSizing: 'border-box',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: theme.palette.secondary.bg,
    padding: '12px 18px 18px 18px',
    gap: '40px',
    borderRadius: '18px', transition: '500ms ease',
    boxShadow: theme.palette.primary.boxShadow,
    "@media (max-width: 600px)": {
        padding: '8px',
        gap: '20px',
    },
    marginBottom: '30px'
}))
const FlexRow = styled(Box)(({ theme }) => ({
    display: 'flex', width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
}))
const FlexColumn = styled(Box)(({ theme }) => ({
    display: 'flex', flexDirection: 'column',
    justifyContent: 'space-between', width: '100%',
    alignItems: 'center',
}))
const RadioButton = styled(FormControlLabel)(({ theme }) => ({
    color: theme.palette.primary.darkGray,

    '&& .Mui-checked + .MuiTypography-root': {
        color: theme.palette.primary.text
    },
    '&& .MuiTypography-root': {
        fontSize: '14px',
    }
}))

const CreateProposal = ({ openTermsModal }) => {
    const [entranceFeeChecked, setEntranceFeeChecked] = useState(false)
    const [entranceFeeInvitedChecked, setEntranceFeeInvitedChecked] = useState(false)
    const [termsChecked, setTermsChecked] = useState(false)
    const [proposalType, setProposalType] = useState('comment')
    const [eventForm, setEventForm] = useState({
        date: '',
        time: '',
        duration: '',
        subject: '',
        capicity: null,
        entrance_fee: null,
        entrance_fee_invited: null,
    })

    const handleFormChange = (e) => {
        if (e.target.type == 'number') {
            setEventForm(prev => ({
                ...prev,
                [e.target.name]: Number(e.target.value)
            }))
        }
        else {
            setEventForm(prev => ({
                ...prev,
                [e.target.name]: e.target.value
            }))
        }
    }

    return (
        <>
            <Container >
                <FlexColumn sx={{ gap: { xs: '10px', sm: '16px' } }}>
                    <Typography sx={{ color: 'primary.text', fontSize: { xs: '18px', sm: '22px' } }}>
                        Proposal Details
                    </Typography>
                </FlexColumn>
                <FlexColumn sx={{ gap: '16px' }}>
                    <FlexRow sx={{ flexDirection: { xs: 'column', sm: 'row' }, gap: '12px', alignItems: 'start !important' }}>
                        <FlexColumn sx={{ gap: '8px' }}>
                            <FlexRow>
                                <MyInput
                                    value={eventForm.date}
                                    name={"date"}
                                    onChange={handleFormChange}
                                    label={'Date*'} width={'100%'}
                                    icon={<Coin color="#BEA2C5" />} type={'string'} id={'date'}
                                />
                            </FlexRow>
                        </FlexColumn>
                        <FlexColumn sx={{ gap: '8px' }}>
                            <FlexRow>
                                <MyInput
                                    value={eventForm.time}
                                    name={"time"}
                                    onChange={handleFormChange}
                                    label={'Time*'} width={'100%'}
                                    icon={<BrandingWatermark sx={{ color: "#BEA2C5" }} />} type={'string'} id={'time'}
                                />
                            </FlexRow>
                        </FlexColumn>
                    </FlexRow>
                    <FlexRow sx={{ flexDirection: { xs: 'column', sm: 'row' }, gap: '12px', alignItems: 'start !important' }}>
                        <FlexColumn sx={{ gap: '8px' }}>
                            <FlexRow>
                                <MyInput
                                    value={eventForm.duration}
                                    name={"duration"}
                                    onChange={handleFormChange}
                                    label={'Duration*'} width={'100%'}
                                    icon={<Coin color="#BEA2C5" />} type={'string'} id={'duration'}
                                />
                            </FlexRow>
                        </FlexColumn>
                        <FlexColumn sx={{ gap: '8px' }}>
                            <FlexRow>
                                <MyInput
                                    value={eventForm.subject}
                                    name={"subject"}
                                    onChange={handleFormChange}
                                    label={'Subject*'} width={'100%'}
                                    icon={<BrandingWatermark sx={{ color: "#BEA2C5" }} />} type={'string'} id={'subject'}
                                />
                            </FlexRow>
                        </FlexColumn>
                    </FlexRow>
                    <FlexRow sx={{ flexDirection: { xs: 'column', sm: 'row' }, gap: '12px', alignItems: 'start !important' }}>
                        <FlexColumn sx={{ gap: '8px' }}>
                            <FlexRow>
                                <MyInput
                                    value={eventForm.capicity}
                                    name={"capicity"}
                                    onChange={handleFormChange}
                                    label={'Capicity*'} width={'100%'}
                                    icon={<Coin color="#BEA2C5" />} type={'number'} id={'capicity'}
                                />
                            </FlexRow>
                        </FlexColumn>
                        <FlexColumn sx={{ gap: '8px' }}>
                            <FlexRow>
                                <SelectInput
                                    tabs={['All YouWho Users']} label={'Participants*'}
                                    // handleSelect={(e) => setPVRoom(pvGalleries.filter(gal => gal.gal_name == e.target.id))}
                                    // value={pvRoom ? pvGalleries.filter(gal => gal.id == pvRoom[0].id)[0].gal_name : ''}
                                    id="event-participant"
                                    width={'100%'} icon={<Coin color="#BEA2C5" />} />
                            </FlexRow>
                        </FlexColumn>
                    </FlexRow>
                    <FlexRow sx={{ flexDirection: { xs: 'column', sm: 'row' }, gap: '12px', alignItems: 'start !important' }}>
                        <FlexColumn sx={{ gap: '8px' }}>
                            <FlexRow sx={{ padding: '8px 0 12px 20px' }}>
                                <FormGroup>
                                    <FormControlLabel
                                        control={
                                            <Checkbox checked={entranceFeeChecked} onChange={(e) => setEntranceFeeChecked(e.target.checked)} />
                                        }
                                        label="Having Entrance Fee"
                                        sx={{
                                            color: 'primary.darkGray',
                                            '& .MuiTypography-root': {
                                                fontSize: '12px',
                                            },
                                            '&& .MuiFormControlLabel-asterisk': {
                                                color: 'primary.main',
                                                fontSize: '16px',
                                            }
                                        }}
                                    />
                                </FormGroup>
                            </FlexRow>
                        </FlexColumn>
                        {
                            entranceFeeChecked ?
                                <FlexColumn sx={{ gap: '8px' }}>
                                    <FlexRow>
                                        <MyInput
                                            value={eventForm.entrance_fee}
                                            name={"entrance_fee"}
                                            onChange={handleFormChange}
                                            label={'Entrance Fee'} width={'100%'}
                                            icon={<Coin color="#BEA2C5" />} type={'number'} id={'entrance_fee'}
                                        />
                                    </FlexRow>
                                </FlexColumn> : <></>}
                    </FlexRow>
                    <FlexRow>
                        <MyInput
                            value={eventForm.description}
                            name={"description"}
                            onChange={handleFormChange}
                            label={'Description'} width={'100%'}
                            icon={<Description sx={{ color: "#BEA2C5" }} />} type={'string'} id={'description'}
                        />
                    </FlexRow>
                </FlexColumn>
                <FlexColumn>
                    <FlexRow sx={{ border: 'solid 1px #dedede', borderRadius: '16px', boxSizing: 'border-box', padding: '16px' }}>
                        <Typography sx={{flexGrow:'1'}}>Proposal Type *</Typography>

                        <Box sx={{flexGrow:'1'}}>
                        <FormControl>
                            <RadioGroup
                                aria-labelledby="demo-controlled-radio-buttons-group"
                                name="controlled-radio-buttons-group"
                                value={proposalType}
                                onChange={e => setProposalType(e.target.value)}
                            >
                                <RadioButton value="comment" control={<Radio />} label="Leave Comment" />
                                <RadioButton value="question_comment" control={<Radio />} label="Yes/No Question + Leave Comment" />
                                <RadioButton value="question" control={<Radio />} label="Yes/No Question" />
                                <RadioButton value="comment_more_item_select" control={<Radio />} label="More Than One Item Can Select + Leave Comment" />
                                <RadioButton value="more_item_select" control={<Radio />} label="More Than One Item Can Select" />
                                <RadioButton value="comment_one_item_select" control={<Radio />} label="Only One Item Can Select + Leave Comment" />
                                <RadioButton value="one_item_select" control={<Radio />} label="Only One Item Can Select" />
                            </RadioGroup>
                        </FormControl>
                        </Box>
                    </FlexRow>
                </FlexColumn>
            </Container>
            <Container >
                <FlexColumn sx={{ gap: { xs: '10px', sm: '16px' } }}>
                    <Typography sx={{ color: 'primary.text', fontSize: { xs: '18px', sm: '22px' } }}>
                        Cover Of Event
                    </Typography>
                </FlexColumn>
                <FlexColumn sx={{ gap: { xs: '10px', sm: '16px' } }}>
                    <ButtonOutline fontColor={'#4C00AF'} w={'100%'} text={'Set Image For  Cover Of The Event'} />
                </FlexColumn>
            </Container>
            <Container >
                <FlexColumn sx={{ gap: { xs: '10px', sm: '16px' } }}>
                    <Typography sx={{ color: 'primary.text', fontSize: { xs: '18px', sm: '22px' } }}>
                        Friends Invitation
                    </Typography>
                </FlexColumn>
                <FlexColumn sx={{ gap: { xs: '10px', sm: '16px' } }}>
                    <FlexRow sx={{ paddingLeft: '20px' }}>
                        <IosSwitch label={'Different Entrance Fee For My Invited Friends'} />
                    </FlexRow>
                </FlexColumn>
                <FlexRow sx={{ flexDirection: { xs: 'column', sm: 'row' }, gap: '12px', alignItems: 'start !important' }}>
                    <FlexColumn sx={{ gap: '8px' }}>
                        <FlexRow sx={{ padding: '8px 0 12px 20px' }}>
                            <FormGroup>
                                <FormControlLabel
                                    control={
                                        <Checkbox checked={entranceFeeInvitedChecked} onChange={(e) => setEntranceFeeInvitedChecked(e.target.checked)} />
                                    }
                                    label="Free For Invited Friends"
                                    sx={{
                                        color: 'primary.darkGray',
                                        '& .MuiTypography-root': {
                                            fontSize: '12px',
                                        },
                                        '&& .MuiFormControlLabel-asterisk': {
                                            color: 'primary.main',
                                            fontSize: '16px',
                                        }
                                    }}
                                />
                            </FormGroup>
                        </FlexRow>
                    </FlexColumn>
                    <FlexColumn sx={{ gap: '8px' }}>
                        <FlexRow>
                            <MyInput
                                value={eventForm.entrance_fee_invited}
                                name={"entrance_fee_invited"}
                                onChange={handleFormChange}
                                label={'Entrance Fee For Invited Friends'} width={'100%'}
                                icon={<Coin color="#BEA2C5" />} type={'number'} id={'entrance_fee_invited'}
                            />
                        </FlexRow>
                    </FlexColumn>
                </FlexRow>
                <FlexColumn sx={{ gap: '12px' }}>
                    <FlexRow >
                        <MyInput
                            // value={eventForm.current_price}
                            name={"search-friends"}
                            // onChange={handleFormChange}
                            label={'Search From Your Friends'} width={'100%'}
                            icon={<UserSearch color="#BEA2C5" />} type={'string'} id={'search-friends'}
                        />
                    </FlexRow>
                </FlexColumn>

            </Container>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Checkbox checked={termsChecked} onChange={(e) => setTermsChecked(e.target.checked)} />
                <Typography onClick={() => setTermsChecked(prev => !prev)} sx={{ display: 'flex', fontSize: '12px', cursor: 'pointer', color: 'primary.darkGray' }}>
                    I Accept YouWho Event&nbsp;<Typography onClick={(e) => openTermsModal(e)} sx={{ color: '#4C00AF', fontWeight: 'bold', textDecoration: 'underline', fontSize: '12px' }}>Terms & Conditions</Typography>
                </Typography>

            </Box>

            <Box sx={{ width: '100%', maxWidth: '400px', pt: '14px', pb: '48px' }}>
                <ButtonPurple w='100%' text={'Send Request'} />
            </Box>
        </>
    )
}

export default CreateProposal