import { Box, Typography } from '@mui/material'
import { useRef } from 'react'
import { BG_URL, PUBLIC_URL } from '../../utils/utils'
import pic from "../../assets/face-pro.svg";
import { API_CONFIG } from '../../config';



const EventAvatar = ({ user, index, handleOpenModal }) => {

    const { username, avatar } = user

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '3px', alignItems: 'center' }}>
            <Box
                sx={{
                    color: 'primary.main',
                    width: { xs: '68px', md: '72px' },
                    height: { xs: '68px', md: '72px' },
                    border: 'solid 2.5px',
                    borderRadius: '50%',
                    boxSizing: 'border-box',
                    minWidth: { xs: '68px', md: '72px' },
                    padding: '2px'
                }}
                onClick={() => handleOpenModal(user, index)}
            >
                <Box
                    sx={{
                        // background: avatar ? `url('${API_CONFIG.API_URL}/${avatar}')` : `${BG_URL(PUBLIC_URL(`${pic}`))} no-repeat center`,
                        background: avatar ? `url(${avatar})` : `${BG_URL(PUBLIC_URL(`${pic}`))} no-repeat center`,
                        borderRadius: '50%',
                        width: '100%', height: '100%',
                        backgroundSize: avatar ? { xs: '63px 63px', md: '67px 67px' } : { xs: '68px 68px', md: '72px 72px' }, cursor: 'pointer',
                    }}
                >
                </Box>
            </Box>

            <Typography sx={{ textAlign: 'center', color: 'primary.text', fontSize: '14px', width: '50px', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>{username}</Typography>
        </Box>
    )
}

export default EventAvatar