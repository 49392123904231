import styled from "@emotion/styled";
import { Box, Popover, Typography } from "@mui/material";
import { useState } from "react";


const Button = styled(Box)(({ theme }) => ({
    boxSizing: 'border-box',
    display: 'flex', alignItems: 'center', justifyContent: 'center',
    // borderRadius: '12px',
    outline: 'none',
    color: 'white',
    border: 'none',
    textTransform: 'capitalize',
    fontWeight: 500,
    // cursor: 'pointer',
    // backgroundColor: theme.palette.secondary.main,
    // boxShadow: theme.palette.secondary.boxShadow,
}))
const ButtonPurple = ({ id, onClick, text, w, px, disabled, icon, mt, height, prevIcon, nextIcon, fontSize, br, popText }) => {
    const [hoverAlert, setHoverAlert] = useState(false)
    return (
        <Button
            id={id}
            onMouseEnter={() => setHoverAlert(true)}
            onMouseLeave={() => setHoverAlert(false)}
            onClick={disabled ? undefined : onClick}
            sx={(theme) => ({
                height: height ? height : '40px',
                mt: mt ? mt : undefined, fontSize: fontSize ? fontSize : '14px', borderRadius: br ? br : '12px',
                backgroundColor: disabled ? 'primary.disabled' : 'secondary.main',
                width: w ? w : '100px', px: px ? px : 'unset', cursor: disabled ? 'auto' : 'pointer',
                boxShadow: disabled ? theme.palette.primary.boxShadowInset : theme.palette.primary.boxShadow,
            })}>
            {prevIcon ? prevIcon : undefined}
            &nbsp;{text}&nbsp;
            {nextIcon ? nextIcon : undefined}
            {popText && disabled &&
                <Popover id={'button-pop'}
                    open={hoverAlert}
                    anchorEl={window.document.getElementById(id)}
                    onClose={() => setHoverAlert(false)}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                >
                    <Typography sx={{ p: 2 }}>{popText}</Typography>
                </Popover>}
        </Button>
    );
}

export default ButtonPurple;