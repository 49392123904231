import { useEffect, useRef, useState } from "react";
import { PUBLIC_API } from "../../utils/data/public_api";
import { Box, CircularProgress, Typography } from "@mui/material";
import { RelationCard } from "../utils";
import { useSelector } from "react-redux";
import { AUTH_API } from "../../utils/data/auth_api";
import { API_CONFIG } from "../../config";
import Pagination from "../pagination";
import { toast } from "react-toastify";
import generateSignature from "../../utils/signatureUtils";

const MyFriendSuggestions = ({
    // sendAllieRequest,
    // sendFriendRequest,
    shareClick,
    removeAllie,
    //  removeFriend,
    search, searchResults, setAllSuggestions, activeTab,
    // removeFollowing,
    updateState,
    setUpdateState }) => {
    const globalUser = useSelector(state => state.userReducer)
    const [loadings, setLoadings] = useState(true)
    const [suggestions, setSuggestions] = useState([])
    const apiCall = useRef(undefined)
    const [err, setErr] = useState(undefined)
    const [from, setFrom] = useState(0)
    const [to, setTo] = useState(30)
    const [pgTabs, setPgTabs] = useState([1])
    const [selectedTab, setSelectedTab] = useState(1)
    const toastId = useRef(null);
    const loading = () => {
        toastId.current = toast.loading("Please wait...")
        console.log(toastId)
    }
    const updateToast = (success, message) => {
        success ? toast.update(toastId.current, { render: message, type: "success", isLoading: false, autoClose: 3000 })
            : toast.update(toastId.current, { render: message, type: "error", isLoading: false, autoClose: 3000 })
    }
    const sendFriendRequest = async (receiver, sender) => {
        loading();
        let current_tab = activeTab
        // setActiveTab('loading')
        let data = {
            owner_cid: sender,
            to_screen_cid: receiver,
        }
        let { requestData } = generateSignature(globalUser.privateKey, data)
        console.log(requestData)
        let request = await fetch(`${API_CONFIG.AUTH_API_URL}/fan/send/friend-request`, {
            method: 'POST',
            body: JSON.stringify(requestData),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${globalUser.token}`,
            }
        })
        let response = await request.json()
        console.log(response);
        if (response.message == "Updated Successfully") {
            updateToast(true, 'Followed')
            // setActiveTab(current_tab)
            getUsers()
        } else {
            updateToast(false, response.message)
        }
    }
    const sendAllieRequest = async (receiver, sender,) => {
        loading();
        let current_tab = activeTab
        // setActiveTab('loading')

        let data = {
            owner_cid: sender,
            to_screen_cid: receiver,
        }
        let { requestData } = generateSignature(globalUser.privateKey, data)
        console.log(requestData)
        let request = await fetch(`${API_CONFIG.AUTH_API_URL}/fan/send/friend-request`, {
            method: 'POST',
            body: JSON.stringify(requestData),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${globalUser.token}`,
            }
        })
        let response = await request.json()
        console.log(response);
        if (response.message == "Updated Successfully") {
            // setActiveTab(current_tab)
            getUsers()
            updateToast(true, 'Followed')
        } else {
            updateToast(false, response.message)
        }

    }
    const removeFriend = async (receiver, sender) => {
        loading();
        let current_tab = activeTab
        // setActiveTab('loading')

        let data = {
            owner_cid: sender,
            friend_screen_cid: receiver,
        }
        let { requestData } = generateSignature(globalUser.privateKey, data)
        console.log(requestData)
        let request = await fetch(`${API_CONFIG.AUTH_API_URL}/fan/remove/friend`, {
            method: 'POST',
            body: JSON.stringify(requestData),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${globalUser.token}`,
            }
        })
        let response = await request.json()
        console.log(response);
        if (response.message == "Updated Successfully") {
            updateToast(true, 'Friend Removed')
            // setActiveTab(current_tab)
            getUsers()
        } else {
            updateToast(false, response.message)
        }
    }
    const removeFollowing = async (receiver, sender) => {
        loading();
        let current_tab = activeTab
        // setActiveTab('loading')

        let data = {
            owner_cid: sender,
            friend_screen_cid: receiver,
        }
        let { requestData } = generateSignature(globalUser.privateKey, data)
        console.log(requestData)
        let request = await fetch(`${API_CONFIG.AUTH_API_URL}/fan/remove/following`, {
            method: 'POST',
            body: JSON.stringify(requestData),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${globalUser.token}`,
            }
        })
        let response = await request.json()
        console.log(response);
        if (response.message == "Updated Successfully") {
            updateToast(true, 'Request Removed')
            // setActiveTab(current_tab)
            getUsers()
        } else {
            updateToast(false, response.message)
        }
    }
    const getUsers = async () => {
        try {
            let request = await fetch(`${API_CONFIG.AUTH_API_URL}/fan/get/suggestions/for/?from=${from}&to=${to}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${globalUser.token}`,
                }
            })
            let response = await request.json()
            console.log(response, 'suggs')
            if (!response.is_error) {
                setErr(undefined)
                // setSuggestions(response.data)
                // setAllSuggestions(response.data)
                // setLoading(false)
                // setErr(undefined)

                let frndsArr = response.data.slice((selectedTab - 1) * 15, ((selectedTab - 1) * 15) + 15)
                setSuggestions(frndsArr)
                setAllSuggestions(frndsArr)
                setLoadings(false)
                setErr(undefined)

                if (response.data.length >= 15) {
                    let pagTabs = []
                    let tabNums = response.data.length / 15
                    for (let i = 0; i < tabNums; i++) {
                        pagTabs.push(i + 1)
                    }
                    setPgTabs(pagTabs)
                } else {
                    // console.log('getting frnds !')
                }
                setUpdateState(false)
            }
            else throw response
        }
        catch (err) {
            console.log(err)
            setErr(err.message)
        }
    }
    useEffect(() => {
        getUsers()
        return () => {
            if (apiCall.current) {
                apiCall.current.cancel();
            }
        }
    }, [])
    useEffect(() => {
        // setFrom((selectedTab - 1) * 15)
        setTo((((selectedTab - 1) * 15) + 15) + 15)
    }, [selectedTab])
    useEffect(() => {
        getUsers()
    }, [to])
    useEffect(() => {
        if (updateState)
            getUsers()
    }, [updateState])

    return (
        <>{err ? <Typography sx={{ textTransform: 'capitalize', color: 'primary.error' }}>{err}</Typography> :
            <>{loadings ? <CircularProgress /> :
                <>{searchResults ?
                    <>
                        {searchResults.length > 0 ?
                            <>
                                <Box sx={{ display: 'flex', flexDirection: 'column', gap: '15px', width: '100%' }}>
                                    {searchResults.map((suggestion, index) => (
                                        <RelationCard
                                            updateState={updateState}
                                            key={suggestion.username}
                                            activeTab={activeTab}
                                            getSuggestions={getUsers}
                                            removeAllie={() => removeAllie(suggestion.screen_cid, globalUser.cid)}
                                            removeFriend={() => removeFriend(suggestion.screen_cid, globalUser.cid)}
                                            removeFollowing={() => removeFollowing(suggestion.screen_cid, globalUser.cid)}
                                            image={suggestion.avatar} username={suggestion.username}
                                            sendAllieRequest={() => sendAllieRequest(suggestion.screen_cid, globalUser.cid)}
                                            sendFriendRequest={() =>
                                                sendFriendRequest(suggestion.screen_cid, globalUser.cid)
                                            }
                                            shareClick={shareClick}
                                            amFollowing={suggestion.requested_at}
                                            isAccepted={suggestion.is_accepted}
                                        />
                                    ))}
                                </Box>
                            </>
                            : <Typography
                                sx={{ color: 'primary.text', fontSize: { xs: '12px', sm: '14px' }, textTransform: 'capitalize' }}>
                                No results
                            </Typography>}
                    </>
                    :
                    <>{suggestions.length > 0 ?
                        <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column', gap: '15px', width: '100%' }}>
                            {suggestions.map((suggestion, index) => (
                                <RelationCard
                                    key={suggestion.username}
                                    updateState={updateState}
                                    activeTab={activeTab}
                                    getSuggestions={getUsers}
                                    removeAllie={() => removeAllie(suggestion.screen_cid, globalUser.cid)}
                                    removeFriend={() => removeFriend(suggestion.screen_cid, globalUser.cid)}
                                    image={suggestion.avatar} username={suggestion.username}
                                    sendAllieRequest={() => sendAllieRequest(suggestion.screen_cid, globalUser.cid)}
                                    sendFriendRequest={() =>
                                        sendFriendRequest(suggestion.screen_cid, globalUser.cid)
                                    }
                                    removeFollowing={() => removeFollowing(suggestion.screen_cid, globalUser.cid)}
                                    shareClick={shareClick}
                                    amFollowing={suggestion.requested_at}
                                    isAccepted={suggestion.is_accepted}
                                />
                            ))}
                            <Box sx={{ mt: '30px' }}>
                                <Pagination tabs={pgTabs} selected={selectedTab} setSelectedTab={setSelectedTab} />
                            </Box>
                        </Box>
                        : <Typography
                            sx={{ color: 'primary.text', fontSize: { xs: '12px', sm: '14px' }, textTransform: 'capitalize' }}>
                            No suggestions
                        </Typography>}
                    </>
                }
                </>
            }
            </>
        }</>

    );
}

export default MyFriendSuggestions;