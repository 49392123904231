import { AppBar, Collapse, Divider, Drawer, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Toolbar, Typography } from '@mui/material'
import { ChevronLeft, ExpandLess, ExpandMore, Groups, Inbox, MailOutline, Menu, SpaceDashboardOutlined } from '@mui/icons-material';
import styled from '@emotion/styled';
import { useState } from 'react';

const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
});

const drawerWidth = 240;

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));

const DrawerStyle = styled(Drawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        '&& .MuiDrawer-paper': {
            position: 'absolute',
            // boxShadow: '2px 0px 5px 1px rgba(0, 0, 0, 0.25)',
            borderLeft: '1px solid #ddd',
        },
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
);

const ListItemButtonStyle = styled(ListItemButton)(
    ({ theme }) => ({
        '&.Mui-selected': {
            backgroundColor: '#9747FF',
            color: 'white',
            '&:hover': { backgroundColor: '#9747FF' }
        },
    }),
);
const ListItemSubButtonStyle = styled(ListItemButton)(
    ({ theme }) => ({
        '&.Mui-selected': {
            backgroundColor: '#7C42C7',
            color: 'white',
            '&:hover': { backgroundColor: '#7C42C7' }
        },
    }),
);
const ListItemTextStyle = styled(ListItemText)(
    ({ theme }) => ({
        pointerEvents: 'none',
        '& .MuiListItemText-primary	': { fontSize: '14px' },
    }),
);
const ListItemTextStyleBold = styled(ListItemText)(
    ({ theme }) => ({
        pointerEvents: 'none',
        '& .MuiListItemText-primary	': { fontSize: '14px', fontWeight: '700' },
    }),
);


const Sidebar = ({ open, handleDrawerClose, handleDrawerOpen, selectedSubTab, setSelectedSubTab }) => {
    const [selectedTab, setSelectedTab] = useState([
        false,
        false,
        true,
    ])

    const handleListItemClick = (event, index) => {
        setSelectedTab(prev => {
            let temp = [...prev]
            temp[index] = !temp[index]
            return temp
        });
        handleDrawerOpen()
    };

    const handleSubTabSelect = (event) => {
        setSelectedSubTab(event.target.id)
    }

    return (
        <DrawerStyle variant="permanent" open={open}>
            <DrawerHeader>
                <IconButton onClick={handleDrawerClose}>
                    <ChevronLeft />
                </IconButton>
            </DrawerHeader>
            <Divider />
            <List>
                <ListItem disablePadding sx={{ display: 'block' }}>
                    <ListItemButtonStyle
                        id='dashboard' 
                        selected={selectedSubTab === 'dashboard'}
                        onClick={(event) => {
                            // handleListItemClick(event, 2)
                            handleSubTabSelect(event)
                        }}
                        sx={{
                            minHeight: 48,
                            justifyContent: open ? 'initial' : 'center',
                            px: 2.5,
                        }}
                    >
                        <ListItemIcon
                            sx={{
                                minWidth: 0,
                                mr: open ? 3 : 'auto',
                                justifyContent: 'center',
                            }}
                        >
                            <SpaceDashboardOutlined sx={{ color: selectedSubTab === 'dashboard' ? 'white' : 'black' }} />
                        </ListItemIcon>
                        <ListItemTextStyleBold primary={'Dashboard'} sx={{ opacity: open ? 1 : 0 }} />
                    </ListItemButtonStyle>
                </ListItem>


                <ListItem disablePadding sx={{ display: 'block' }}>
                    <ListItemButtonStyle
                        selected={selectedTab[0]}
                        onClick={(event) => handleListItemClick(event, 0)}
                        sx={{
                            minHeight: 48,
                            justifyContent: open ? 'initial' : 'center',
                            px: 2.5,
                        }}
                    >
                        <ListItemIcon
                            sx={{
                                minWidth: 0,
                                mr: open ? 3 : 'auto',
                                justifyContent: 'center',
                            }}
                        >
                            <MailOutline sx={{ color: selectedTab[0] ? 'white' : 'black' }} />
                        </ListItemIcon>
                        <ListItemTextStyleBold primary={'Messages'}
                            sx={{ opacity: open ? 1 : 0, }}
                        />
                        {
                            open ?
                                selectedTab[0] ? <ExpandLess /> : <ExpandMore />
                                : <></>
                        }
                    </ListItemButtonStyle>
                    <Collapse in={selectedTab[0] && open} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            <ListItemSubButtonStyle sx={{ pl: 4 }} id='contact-us-messages' selected={selectedSubTab === 'contact-us-messages'} onClick={(event) => handleSubTabSelect(event)}>
                                <ListItemTextStyle primary="Contact Us Messages" />
                            </ListItemSubButtonStyle>
                            <ListItemSubButtonStyle sx={{ pl: 4 }} id='withdraw-messages' selected={selectedSubTab === 'withdraw-messages'} onClick={(event) => handleSubTabSelect(event)}>
                                <ListItemTextStyle primary="Withdraw Messages" />
                            </ListItemSubButtonStyle>
                        </List>
                    </Collapse>
                </ListItem>
                <ListItem disablePadding sx={{ display: 'block' }}>
                    <ListItemButtonStyle
                        selected={selectedTab[1]}
                        onClick={(event) => handleListItemClick(event, 1)}
                        sx={{
                            minHeight: 48,
                            justifyContent: open ? 'initial' : 'center',
                            px: 2.5,
                        }}
                    >
                        <ListItemIcon
                            sx={{
                                minWidth: 0,
                                mr: open ? 3 : 'auto',
                                justifyContent: 'center',
                            }}
                        >
                            <Groups sx={{ color: selectedTab[1] ? 'white' : 'black' }} />
                        </ListItemIcon>
                        <ListItemTextStyleBold primary={'Users'} sx={{ opacity: open ? 1 : 0 }} />
                        {
                            open ?
                                selectedTab[1] ? <ExpandLess /> : <ExpandMore />
                                : <></>
                        }
                    </ListItemButtonStyle>

                    <Collapse in={selectedTab[1] && open} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            <ListItemSubButtonStyle sx={{ pl: 4 }} id='users-deposites' selected={selectedSubTab === 'users-deposites'} onClick={(event) => handleSubTabSelect(event)}>
                                <ListItemTextStyle primary="Users Gift Cards" />
                            </ListItemSubButtonStyle>
                            <ListItemSubButtonStyle sx={{ pl: 4 }} id='users-withdrawals' selected={selectedSubTab === 'users-withdrawals'} onClick={(event) => handleSubTabSelect(event)}>
                                <ListItemTextStyle primary="Users Claims" />
                            </ListItemSubButtonStyle>
                            <ListItemSubButtonStyle sx={{ pl: 4 }} id='users-checkouts' selected={selectedSubTab === 'users-checkouts'} onClick={(event) => handleSubTabSelect(event)}>
                                <ListItemTextStyle primary="Users Checkouts" />
                            </ListItemSubButtonStyle>
                            <ListItemSubButtonStyle sx={{ pl: 4 }} id='all-users' selected={selectedSubTab === 'all-users'} onClick={(event) => handleSubTabSelect(event)}>
                                <ListItemTextStyle primary="All Users" />
                            </ListItemSubButtonStyle>
                        </List>
                    </Collapse>
                </ListItem>
            </List>
        </DrawerStyle>
    )
}

export default Sidebar