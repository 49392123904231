import { Box, Typography, TableContainer, Table, TableHead, TableRow, TableBody, TableCell, Paper, CircularProgress, Modal, ClickAwayListener } from '@mui/material'
import styled from "@emotion/styled";
import { ExpandMore } from '@mui/icons-material';
import { API_CONFIG } from '../../config'
import { useSelector } from "react-redux";
import { useEffect, useRef, useState } from 'react'
import { BG_URL, PUBLIC_URL } from '../../utils/utils';
import yCoin from "../../assets/Ycoin.svg"
import pic from "../../assets/face-pro.svg";
import ButtonBorder from '../buttons/buttonBorder';
import { Coin1 } from 'iconsax-react';
import { MyInput } from '../utils';
import { toast } from 'react-toastify';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.MuiTableCell-head`]: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
    },
    [`&.MuiTableCell-body`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

const Container = styled(Box)(({ theme }) => ({
    boxSizing: 'border-box',
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    gap: '12px',
    overflowX: 'auto',
    padding: '8px 0px',
    maxHeight: '75vh',
    '&::-webkit-scrollbar': {
        height: '4px',
        width: '3px',
        background: 'transparent',
        border: 'none',
        borderRadius: '20px !important'
    },
    '&::-webkit-scrollbar-thumb': {
        width: '3px',
        height: '3px',
        background: '#9747ff',
        border: '0.5px solid #9747ff',
        borderRadius: '20px !important'
    },
    '&::-webkit-scrollbar-button': {
        display: 'none',
        width: '1px',
        height: '1px',
        background: '#9747ff',
        border: '0.5px solid #C6BAC5',
        borderRadius: '50% !important'
    },
    "@media (max-width: 600px)": {
        '&::-webkit-scrollbar': {
            display: 'none',
        },
    },
}))

const Avatar = styled(Box)(({ theme }) => ({
    display: 'flex', flexDirection: 'column', width: '80px', alignItems: 'center'
}))

const AdminDashboard = () => {
    const globalUser = useSelector(state => state.userReducer)
    const [treasuries, setTreasuries] = useState(null)
    const [users, setUsers] = useState(null)
    const [openModal, setOpenModal] = useState(false)
    const [openBalanceModal, setOpenBalanceModal] = useState(false)
    const [selectedUsers, setSelectedUsers] = useState([])
    const [tempUser, setTempUser] = useState(null)
    const [modalType, setModalType] = useState('users')
    const [amount, setAmount] = useState(0)
    const [balance, setBalance] = useState(0)
    const toastId = useRef(null);

    const loading = () => {
        toastId.current = toast.loading("Please wait...")
    }
    const handleOpenModal = (type) => {
        if (type == 'airdrop') {
            setOpenModal(true)
        }
        if (type == 'balance') {
            setOpenBalanceModal(true)
        }
    }

    const handleCloseModal = (type) => {
        if (type == 'airdrop') {
            setOpenModal(false)
            setSelectedUsers([])
            setModalType('users')
            setAmount(0)
        }
        if (type == 'balance') {
            setOpenBalanceModal(false)
            setTempUser(null)
            setModalType('users')
            setBalance(0)
        }
    }
    const handleSelectUser = (user) => {
        if (selectedUsers.includes(user)) {
            setSelectedUsers(selectedUsers.filter((selectedUser) => selectedUser !== user));
        } else {
            setSelectedUsers([...selectedUsers, user]);
        }
    };

    useEffect(() => {
        if (globalUser.token) {
            getSysTreasuries()
            getAllUsers()
        }

    }, [globalUser.token])


    const getAllUsers = async () => {
        let request = await fetch(`${API_CONFIG.ADMIN_API_URL}/get-users/?from=0&to=100`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${globalUser.token}`,
                'Content-Type': 'application/json',
            }
        })

        let response = await request.json()
        if (response.status === 200) {
            setUsers(response.data)
        } else {
            console.log(response)
        }
    }

    const getSysTreasuries = async () => {
        let request = await fetch(`${API_CONFIG.ADMIN_API_URL}/treasury/get/all`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${globalUser.token}`,
                'Content-Type': 'application/json',
            }
        })

        let response = await request.json()
        if (response.status === 200) {
            setTreasuries(response.data)
        } else {
            console.log(response)
        }
    }

    const getTotalDebit = (object) => {
        return object.map(item => item.debit)?.reduce((total, num) => (total + num), 0)
    }

    const updateToast = (success, message) => {
        success ? toast.update(toastId.current, { render: message, type: "success", isLoading: false, autoClose: 3000 })
            : toast.update(toastId.current, { render: message, type: "error", isLoading: false, autoClose: 3000 })
    }

    const handleAirdrop = async () => {
        loading()
        for (let user of selectedUsers) {
            const body = {
                user_id: user.id,
                amount: Number(amount)
            }
            let request = await fetch(`${API_CONFIG.ADMIN_API_URL}/airdrop`, {
                method: 'POST',
                body: JSON.stringify(body),
                headers: {
                    'Authorization': `Bearer ${globalUser.token}`,
                    'Content-Type': 'application/json',
                }
            })

            let response = await request.json()
            if (response.status === 200) {
                updateToast(true, response.message)
                handleCloseModal('airdrop')
            } else {
                console.log(response)
                updateToast(false, 'Something went wrong')
            }
        }
    }
    const UpedateUserBalance = async () => {
        loading()
        const body = {
            user_id: tempUser.id,
            new_balance: balance
        }
        let request = await fetch(`${API_CONFIG.ADMIN_API_URL}/update-user-balance`, {
            method: 'POST',
            body: JSON.stringify(body),
            headers: {
                'Authorization': `Bearer ${globalUser.token}`,
                'Content-Type': 'application/json',
            }
        })

        let response = await request.json()
        if (response.status === 200) {
            updateToast(true, response.message)
            handleCloseModal('balance')
        } else {
            console.log(response)
            updateToast(false, 'Something went wrong')
        }
    }


    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%', gap: '20px' }}>
            <Typography sx={{ width: '100%', textAlign: 'center', fontSize: '22px', my: '24px' }}>Dashboard</Typography>
            {treasuries ?
                <Box sx={theme => ({
                    width: '90%', maxWidth: '800px',
                    display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center',
                    gap: '24px', mb: '20px', backgroundColor: theme.palette.secondary.bg,
                    boxShadow: theme.palette.primary.boxShadow, borderRadius: '16px', padding: '16px', boxSizing: 'border-box'
                })}>
                    <Typography sx={{ fontWeight: '600' }}>Transaction Summary</Typography>
                    <Box sx={{
                        width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between', borderTop: 'solid 2px black', pt: '14px',
                        flexDirection: { xs: 'column', sm: 'row' }, gap: { xs: '8px', sm: 'none' }
                    }}>
                        <Box sx={{
                            display: 'flex', alignItems: 'center', gap: '40px', flexGrow: '1', justifyContent: 'center', pt: '10px', pb: { xs: '8px', sm: 'none' }, borderRight: { xs: 'none', sm: 'solid 2px black' },
                            borderBottom: { xs: 'solid 2px #eee', sm: 'none' }
                        }}>
                            <Typography sx={{ fontWeight: '600', fontSize: '14px', alignItems: 'center' }}>Total Credit:</Typography>
                            <Typography sx={{ fontSize: '14px', display: 'flex', gap: '3px', alignItems: 'center' }}>
                                {treasuries[0].current_networth + getTotalDebit(treasuries)}
                                <Box sx={{
                                    backgroundImage: BG_URL(PUBLIC_URL(`${yCoin}`)),
                                    backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'center', width: '14px', height: '14px'
                                }} />
                            </Typography>
                        </Box>
                        <Box sx={{
                            display: 'flex', alignItems: 'center', gap: '40px', flexGrow: '1', justifyContent: 'center', pt: '10px', pb: { xs: '8px', sm: 'none' }, borderRight: { xs: 'none', sm: 'solid 2px black' },
                            borderBottom: { xs: 'solid 2px #eee', sm: 'none' }
                        }}>
                            <Typography sx={{ fontWeight: '600', fontSize: '14px', alignItems: 'center' }}>Total Debit:</Typography>
                            <Typography sx={{ fontSize: '14px', display: 'flex', gap: '3px', alignItems: 'center' }}>
                                {
                                    getTotalDebit(treasuries)
                                }
                                <Box sx={{
                                    backgroundImage: BG_URL(PUBLIC_URL(`${yCoin}`)),
                                    backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'center', width: '14px', height: '14px'
                                }} />
                            </Typography>
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: '40px', flexGrow: '1', justifyContent: 'center', pt: '10px' }}>
                            <Typography sx={{ fontWeight: '600', fontSize: '14px', alignItems: 'center' }}>Current Networth:</Typography>
                            <Typography sx={{ fontSize: '14px', display: 'flex', gap: '3px', alignItems: 'center' }}>
                                {treasuries[0].current_networth}
                                <Box sx={{
                                    backgroundImage: BG_URL(PUBLIC_URL(`${yCoin}`)),
                                    backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'center', width: '14px', height: '14px'
                                }} />
                            </Typography>
                        </Box>
                    </Box>
                    <Box sx={{
                        width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', borderTop: 'solid 2px black', pt: '14px',
                        flexDirection: { xs: 'column', sm: 'row' }, gap: { xs: '8px', sm: 'none' }
                    }}>
                        <ButtonBorder bgcolor={'#efd122'} w={'260px'} fontColor={'#333'} text={'Airdrop'} onClick={()=>handleOpenModal('airdrop')} />
                        <ButtonBorder bgcolor={'#9747ff'} w={'260px'} fontColor={'#fff'} text={'Update Balance'} onClick={()=>handleOpenModal('balance')} />
                    </Box>
                </Box> : null
            }
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '90%', maxWidth: '800px', gap: '18px' }}>
                {
                    treasuries ?
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 700 }} aria-label="customized table">
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell align='center'>ID</StyledTableCell>
                                        <StyledTableCell align='center'>Airdrop</StyledTableCell>
                                        <StyledTableCell align='center'>Debit</StyledTableCell>
                                        <StyledTableCell align='center'>Paid To</StyledTableCell>
                                        <StyledTableCell align='center'>Current Networth</StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        treasuries.length > 0 ?
                                            treasuries.map((treasury, index) => (
                                                <StyledTableRow key={treasury.id}>
                                                    <StyledTableCell align='center' component="th" scope="user">
                                                        {treasury.id}
                                                    </StyledTableCell >
                                                    <StyledTableCell align='center'>{treasury.airdrop}</StyledTableCell>
                                                    <StyledTableCell align='center'>{treasury.debit}</StyledTableCell>
                                                    <StyledTableCell align='center'>{treasury.wallet_info.username}</StyledTableCell>
                                                    <StyledTableCell align='center'>
                                                        {treasury.current_networth}
                                                    </StyledTableCell>
                                                </StyledTableRow>
                                            ))
                                            :
                                            <StyledTableRow>
                                                <StyledTableCell sx={{ textAlign: 'center', color: 'darkgray' }} component="th" scope="user" colSpan={5}>
                                                    No record found
                                                </StyledTableCell>
                                            </StyledTableRow>
                                    }
                                </TableBody>
                            </Table>
                            {/* <MorePopper tabs={deleteTabs} open={open} anchorEl={anchorEl} handleClose={handleClose} /> */}
                        </TableContainer> : <CircularProgress />
                }
            </Box>

            {/* Airdrop Modal */}
            <Modal
                open={openModal}
                onClose={() => handleCloseModal('airdrop')}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                disableScrollLock={true}

            >
                <Box sx={(theme) => ({
                    width: '100%',
                    height: '100%',
                    display: 'flex', alignItems: 'center', justifyContent: 'center',
                    backdropFilter: 'blur(10px)', pointerEvents: 'none'
                })}>
                    {
                        modalType == 'users' ?
                            users ?
                                <Box sx={(theme) => ({
                                    borderRadius: { xs: '0', sm: '24px' },
                                    width: { xs: '100%', sm: '700px' }, height: { xs: '100%', sm: 'auto' },
                                    backgroundColor: 'secondary.bg', boxShadow: theme.palette.primary.boxShadow, boxSizing: 'border-box',
                                    display: 'flex', flexDirection: 'column',
                                    padding: '30px', alignItems: 'center', pointerEvents: 'all'
                                })}>
                                    <Typography sx={{ width: '100%', textAlign: 'center', fontSize: '18px', my: '16px' }}>Select From Users</Typography>

                                    <Container>

                                        {
                                            users?.map(user => (
                                                <Avatar onClick={() => handleSelectUser(user)}>
                                                    <Box
                                                        sx={{
                                                            // background: avatar ? `url('${API_CONFIG.API_URL}/${avatar}')` : `${BG_URL(PUBLIC_URL(`${pic}`))} no-repeat center`,
                                                            background: `${BG_URL(PUBLIC_URL(`${pic}`))} no-repeat center`,
                                                            borderRadius: '50%',
                                                            border: selectedUsers.includes(user) ? 'solid 3px #9747ff' : 'none',
                                                            width: '80px', height: '80px',
                                                            backgroundSize: '80px 80px', cursor: 'pointer',
                                                            boxSizing: 'border-box'
                                                        }}
                                                    >
                                                    </Box>
                                                    <Typography sx={{ maxWidth: '50px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{user.username}</Typography>
                                                </Avatar>
                                            ))
                                        }

                                    </Container>

                                    <ButtonBorder disabled={!selectedUsers.length} bgcolor={'#9747ff'} w={'260px'} fontColor={'#fff'} text={'Next'} onClick={() => setModalType('price')} />


                                </Box>
                                : <CircularProgress />
                            : <></>
                    }
                    {
                        modalType == 'price' ?
                            <Box sx={(theme) => ({
                                borderRadius: { xs: '0', sm: '24px' },
                                width: { xs: '100%', sm: '700px' }, height: { xs: '100%', sm: 'auto' },
                                backgroundColor: 'secondary.bg', boxShadow: theme.palette.primary.boxShadow, boxSizing: 'border-box',
                                display: 'flex', flexDirection: 'column',
                                padding: '30px', alignItems: 'center', pointerEvents: 'all'
                            })}>
                                <Typography sx={{ width: '100%', textAlign: 'center', fontSize: '18px', my: '16px' }}>Select Airdrop Amount</Typography>

                                <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%', py: '30px' }}>
                                    <MyInput
                                        value={amount}
                                        name={"amount"}
                                        onChange={(e) => setAmount(e.target.value)}
                                        label={'Amount'} width={'400px'}
                                        icon={<Coin1 color="#BEA2C5" />} type={'number'} id={'capicity'}
                                    />
                                </Box>
                                <ButtonBorder disabled={amount == 0} bgcolor={'#9747ff'} w={'260px'} fontColor={'#fff'} text={'Submit'} onClick={handleAirdrop} />

                            </Box>
                            : <></>
                    }
                </Box>

            </Modal>

            {/* Balance Modal */}
            <Modal
                open={openBalanceModal}
                onClose={() => handleCloseModal('balance')}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                disableScrollLock={true}

            >
                <Box sx={(theme) => ({
                    width: '100%',
                    height: '100%',
                    display: 'flex', alignItems: 'center', justifyContent: 'center',
                    backdropFilter: 'blur(10px)', pointerEvents: 'none'
                })}>
                    {
                        modalType == 'users' ?
                            users ?
                                <Box sx={(theme) => ({
                                    borderRadius: { xs: '0', sm: '24px' },
                                    width: { xs: '100%', sm: '700px' }, height: { xs: '100%', sm: 'auto' },
                                    backgroundColor: 'secondary.bg', boxShadow: theme.palette.primary.boxShadow, boxSizing: 'border-box',
                                    display: 'flex', flexDirection: 'column',
                                    padding: '30px', alignItems: 'center', pointerEvents: 'all'
                                })}>
                                    <Typography sx={{ width: '100%', textAlign: 'center', fontSize: '18px', my: '16px' }}>Select From Users</Typography>

                                    <Container>

                                        {
                                            users?.map(user => (
                                                <Avatar onClick={() => setTempUser(user)}>
                                                    <Box
                                                        sx={{
                                                            // background: avatar ? `url('${API_CONFIG.API_URL}/${avatar}')` : `${BG_URL(PUBLIC_URL(`${pic}`))} no-repeat center`,
                                                            background: `${BG_URL(PUBLIC_URL(`${pic}`))} no-repeat center`,
                                                            borderRadius: '50%',
                                                            border: tempUser == user ? 'solid 3px #9747ff' : 'none',
                                                            width: '80px', height: '80px',
                                                            backgroundSize: '80px 80px', cursor: 'pointer',
                                                            boxSizing: 'border-box'
                                                        }}
                                                    >
                                                    </Box>
                                                    <Typography sx={{ maxWidth: '50px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{user.username}</Typography>
                                                </Avatar>
                                            ))
                                        }

                                    </Container>

                                    <ButtonBorder disabled={!tempUser} bgcolor={'#9747ff'} w={'260px'} fontColor={'#fff'} text={'Next'} onClick={() => setModalType('new-balance')} />

                                </Box>
                                : <CircularProgress />
                            : <></>
                    }
                    {
                        modalType == 'new-balance' ?
                            <Box sx={(theme) => ({
                                borderRadius: { xs: '0', sm: '24px' },
                                width: { xs: '100%', sm: '700px' }, height: { xs: '100%', sm: 'auto' },
                                backgroundColor: 'secondary.bg', boxShadow: theme.palette.primary.boxShadow, boxSizing: 'border-box',
                                display: 'flex', flexDirection: 'column',
                                padding: '30px', alignItems: 'center', pointerEvents: 'all'
                            })}>
                                <Typography sx={{ width: '100%', textAlign: 'center', fontSize: '18px', my: '16px' }}>Select New Balance</Typography>

                                <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%', py: '30px' }}>
                                    <MyInput
                                        value={balance}
                                        name={"new-balance"}
                                        onChange={(e) => setBalance(Number(e.target.value))}
                                        label={'New Balance'} width={'400px'}
                                        icon={<Coin1 color="#BEA2C5" />} type={'number'} id={'capicity'}
                                    />
                                </Box>
                                <ButtonBorder disabled={balance == 0} bgcolor={'#9747ff'} w={'260px'} fontColor={'#fff'} text={'Submit'} onClick={UpedateUserBalance} />

                            </Box>
                            : <></>
                    }
                </Box>
            </Modal>
        </Box>
    )
}

export default AdminDashboard