import { Box, Typography, TableContainer, Table, TableHead, TableRow, TableBody, TableCell, Paper, CircularProgress, Modal, ClickAwayListener } from '@mui/material'
import { Delete, ModeEdit, Search } from '@mui/icons-material';
import { API_CONFIG } from '../../config'
import { useSelector } from "react-redux";
import { useEffect, useRef, useState } from 'react'
import styled from '@emotion/styled';
import { MorePopper, MyInput, SelectInput } from '../utils'
import { Edit } from 'iconsax-react';
import ButtonPurple from '../buttons/buttonPurple';
import ButtonOutline from '../buttons/buttonOutline';
import { toast } from "react-toastify";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.MuiTableCell-head`]: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
    },
    [`&.MuiTableCell-body`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

const AllUsers = () => {
    const globalUser = useSelector(state => state.userReducer)
    const [users, setUsers] = useState(null)
    const [deleteModal, setDeleteModal] = useState(false)
    const [modalType, setModalType] = useState(null)
    const [userTemp, setUserTemp] = useState(null)
    const [anchorEl, setAnchorEl] = useState(null);
    const [userRole, setUserRole] = useState(null)
    const [username, setUsername] = useState(null)
    const [query, setQuery] = useState('')
    const open = Boolean(anchorEl);
    const toastId = useRef(null);
    const loading = () => {
        toastId.current = toast.loading("Please wait...")
    }

    const updateToast = (success, message) => {
        success ? toast.update(toastId.current, { render: message, type: "success", isLoading: false, autoClose: 3000 })
            : toast.update(toastId.current, { render: message, type: "error", isLoading: false, autoClose: 3000 })
    }

    useEffect(() => {
        if (globalUser.token) {
            getAllUsers()
        }

    }, [globalUser.token])

    const getAllUsers = async () => {
        let request = await fetch(`${API_CONFIG.ADMIN_API_URL}/get-users/?from=0&to=100`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${globalUser.token}`,
                'Content-Type': 'application/json',
            }
        })

        let response = await request.json()
        if (response.status === 200) {
            setUsers(response.data)
        } else {
            console.log(response)
        }
    }

    const handleDeleteWallet = async (user) => {
        loading()

        let request = await fetch(`${API_CONFIG.ADMIN_API_URL}/delete-wallet/${user.id}`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${globalUser.token}`,
                'Content-Type': 'application/json',
            }
        })

        let response = await request.json()
        if (response.status === 200) {
            updateToast(true, response.message)
            handleCloseDeleteModal()
            getAllUsers()
        } else {
            console.log(response)
            updateToast(false, 'Something went wrong')
        }
    }

    const handleEditUser = async (user) => {
        loading()

        const body = {
            user_id: user.id,
            role: userRole,
            username: username,
            identifier: user.identifier,
        }

        let request = await fetch(`${API_CONFIG.ADMIN_API_URL}/edit-user`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${globalUser.token}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(body)
        })

        let response = await request.json()
        if (response.status === 200) {
            updateToast(true, response.message)
            handleCloseDeleteModal()
            getAllUsers()
        } else {
            console.log(response)
            updateToast(false, 'Something went wrong')
        }
    }
    const handleCloseDeleteModal = () => {
        setDeleteModal(false)
        setUserTemp(null)
        setUserRole(null)
        setUsername(null)
    }

    const handleClick = (event, user) => {
        if (!open) {
            setAnchorEl(event.currentTarget);
            setUserTemp(user)
        }
        else
            setAnchorEl(null)
    };
    const handleClose = (e) => {
        setAnchorEl(null);
    };
    const handleClickAway = () => {
        setAnchorEl(null);
    }

    const deleteTabs = [
        {
            text: 'Delete User Account',
            id: 'delete-user-account',
            onClick: () => { },
            fColor: 'primary.gray',
            // , icon: <Profile size={'16px'} />
        },
        {
            text: 'Delete User Wallet',
            id: 'delete-users-wallet',
            disabled: false,
            onClick: () => {
                setAnchorEl(null)
                setDeleteModal(true)
                setModalType('wallet')
            },
            // icon: <DetailsRounded sx={{ fontSize: '16px' }} />
        },
    ]

    const openEdit = (event, user) => {
        setDeleteModal(true)
        setModalType('edit')
        setUserTemp(user)
        setUserRole(user.user_role)
        setUsername(user.username)
    }

    const handleSelect = (e) => {
        e.preventDefault()
        setUserRole(e.target.id)
    }

    const search = (data) => {
        return data?.filter(item => (item.username.toLowerCase().includes(query) || item.mail?.toLowerCase().includes(query)));
    }


    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%', gap: '20px' }}>
            <Typography sx={{ width: '100%', textAlign: 'center', fontSize: '22px', my: '24px' }}>All Users</Typography>
            <Box sx={{ width: '90%', maxWidth: '700px' }}>
                <MyInput id='search-input' value={query} onChange={(e) => setQuery(e.target.value)} placeholder={'search by username or mail'} width={'100%'} icon={<Search sx={{ color: 'primary.darkGray' }} />} />
            </Box>
            <ClickAwayListener onClickAway={handleClickAway}>
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '90%', maxWidth: '800px', gap: '18px' }}>
                    {
                        users ?
                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCell >Row</StyledTableCell>
                                            <StyledTableCell>Username</StyledTableCell>
                                            <StyledTableCell>Mail</StyledTableCell>
                                            <StyledTableCell>Balance</StyledTableCell>
                                            <StyledTableCell sx={{ textAlign: 'center' }} align="right">...</StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            search(users).length > 0 ?
                                                search(users).map((user, index) => (
                                                    <StyledTableRow key={index + 1}>
                                                        <StyledTableCell component="th" scope="user">
                                                            {index + 1}
                                                        </StyledTableCell>
                                                        <StyledTableCell >{user.username}</StyledTableCell>
                                                        <StyledTableCell>{user.mail ? user.mail : '_'}</StyledTableCell>
                                                        <StyledTableCell>{user.balance}</StyledTableCell>
                                                        <StyledTableCell sx={{ display: 'flex', gap: '16px', pr: '16px' }} align="right">
                                                            <div onClick={(event) => handleClick(event, user)}>
                                                                <Delete sx={{ cursor: 'pointer' }} />
                                                            </div>
                                                            <div onClick={(event) => openEdit(event, user)}>
                                                                <ModeEdit sx={{ cursor: 'pointer' }} />
                                                            </div>
                                                        </StyledTableCell>
                                                    </StyledTableRow>
                                                ))
                                                :
                                                <StyledTableRow>
                                                    <StyledTableCell sx={{ textAlign: 'center', color: 'darkgray' }} component="th" scope="user" colSpan={5}>
                                                        No record found
                                                    </StyledTableCell>
                                                </StyledTableRow>
                                        }
                                    </TableBody>
                                </Table>
                                <MorePopper tabs={deleteTabs} open={open} anchorEl={anchorEl} handleClose={handleClose} />
                            </TableContainer> : <CircularProgress />
                    }
                </Box>
            </ClickAwayListener>

            <Modal
                open={deleteModal}
                onClose={handleCloseDeleteModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                disableScrollLock={true}

            >
                <Box sx={(theme) => ({
                    width: '100%',
                    height: '100%',
                    display: 'flex', alignItems: 'center', justifyContent: 'center',
                    backdropFilter: 'blur(10px)', pointerEvents: 'none'
                })}>
                    {userTemp ?
                        <Box sx={(theme) => ({
                            borderRadius: { xs: '0', sm: '24px' },
                            width: { xs: '100%', sm: 'max-content' }, height: { xs: '100%', sm: 'auto' },
                            backgroundColor: 'secondary.bg', boxShadow: theme.palette.primary.boxShadow, boxSizing: 'border-box',
                            display: 'flex', flexDirection: 'column',
                            padding: '30px', alignItems: 'center', pointerEvents: 'all'
                        })}>
                            {
                                modalType === 'wallet' ?
                                    <>
                                        <Typography sx={{ width: '100%', textAlign: 'center', fontSize: '16px', my: '24px' }}>Are You Sure You Want To Delete
                                            <b> {userTemp.username}</b>
                                            Wallet ?</Typography>
                                        <Box sx={{ display: 'flex', gap: '12px' }}>
                                            <ButtonPurple w={'max-content'} text={'Delete'} onClick={() => handleDeleteWallet(userTemp)} px={'12px'} />
                                            <ButtonOutline w={'max-content'} text={'Cancel'} onClick={handleCloseDeleteModal} px={'12px'} />
                                        </Box>
                                    </>
                                    :
                                    <></>
                            }
                            {
                                modalType === 'account' ?
                                    <Typography sx={{ width: '100%', textAlign: 'center', fontSize: '16px', my: '24px' }}>Are You Sure You Want To Delete {userTemp.username} Account ?</Typography>
                                    : <></>
                            }
                            {
                                modalType === 'edit' ?
                                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '5px', width: '300px' }}>
                                        <Typography sx={{ width: '100%', textAlign: 'center', fontSize: '16px', my: '8px' }}>Edit User</Typography>

                                        <Box sx={{ width: '90%', display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '10px' }}>
                                            <MyInput width={'100%'} label={'user id'} type="number" value={userTemp.id} />
                                            <MyInput width={'100%'} label={'username'} value={username} onChange={(e) => setUsername(e.target.value)} />
                                            <SelectInput width={'100%'} label={'user role'} value={userRole} tabs={['User', 'Admin']} handleSelect={handleSelect} />
                                        </Box>

                                        <Box sx={{ display: 'flex', gap: '16px', mt: '10px' }}>
                                            <ButtonPurple w={'max-content'} text={'Submit'} onClick={() => handleEditUser(userTemp)} px={'12px'} />
                                            <ButtonOutline w={'max-content'} text={'Cancel'} onClick={handleCloseDeleteModal} px={'12px'} />
                                        </Box>
                                    </Box>
                                    : <></>
                            }
                        </Box>
                        : <CircularProgress />}
                </Box>
            </Modal>
        </Box>
    )
}

export default AllUsers