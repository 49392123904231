// import { Avatar } from "@mui/joy";
import styled from "@emotion/styled";
import { Box, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import DashBar from "../components/dashboard/DashBar.js";
import ProfilePanel from "../components/dashboard/profilePanel";
import { ToastContainer } from 'react-toastify';
import '../index.css'
import VerifyPhoneModal from "../components/user/auth/verifyPhoneModal.js";
import EventsLayout from "../components/events/eventsLayout.js";
import ProfileSlider from "../components/events/profileSlider.js";
import { Tab, Tabs } from "../components/utils";
import EventsHome from "../components/events/eventsHome.js";
import { ImageSearchOutlined, KeyboardArrowDown } from "@mui/icons-material";
import CreateEvent from "../components/events/createEvent.js";
import JoinedEvents from "../components/events/joinedEvents.js";
import MyEvents from "../components/events/myEvents.js";

const ShowPanel = styled(Box)(({ theme }) => ({
    transition: '500ms ease',
    display: 'flex',
}))

const Panel = styled(Box)(({ theme }) => ({
    color: theme.palette.primary.text,
    transition: '500ms ease', boxSizing: 'border-box',
    width: '100%',
    // borderRadius: '24px',
    display: 'flex', flexDirection: 'column', alignItems: 'center',

}))

const ScrollablePanel = styled(Box)(({ theme }) => ({
    color: theme.palette.primary.text,
    width: '100%',
    overflowX: 'hidden',
    '&::-webkit-scrollbar': {
        width: '3px',
        background: 'transparent',
        border: 'none',
        borderRadius: '20px !important'
    },
    '&::-webkit-scrollbar-thumb': {
        width: '3px',
        height: '3px',
        background: '#9747ff',
        border: '0.5px solid #9747ff',
        borderRadius: '20px !important'
    },
    '&::-webkit-scrollbar-button': {
        width: '1px',
        height: '1px',
        background: '#9747ff',
        border: '0.5px solid #C6BAC5',
        borderRadius: '50% !important'
    },
    "@media (max-width: 600px)": {
        '&::-webkit-scrollbar': {
            display: 'none',
        },
    },
}))



const Events = ({ switchTheme, theme }) => {
    const globalUser = useSelector(state => state.userReducer)
    const [searchParams] = useSearchParams();
    const [openModal, setOpenModal] = useState(false)
    const [progressBarOpen, setProgressBarOpen] = useState(false)
    const [filterOpen, setFilterOpen] = useState(false)
    const [activeTab, setActiveTab] = useState(window.location.hash ? window.location.hash.replace('#', '') : 'home')

    const listenScrollEvent = e => {
        let card = window.document.getElementById('events-slider')
        let insidePanel = window.document.getElementById('scrollable-events-panel-inside')


        if (window.document.getElementById("scrollable-events-panel-inside").scrollTop > 0 || window.document.getElementById("scrollable-events-panel").scrollTop > 0) {
            card.classList.remove("eventsSliderBeforeScroll")
            card.classList.add("eventsSliderAfterScroll")
            insidePanel.classList.remove("insidePanelEventsBeforeScroll")
            insidePanel.classList.add("insidePanelEventsAfterScroll")
        }
        else {
            card.classList.remove("eventsSliderAfterScroll")
            card.classList.add("eventsSliderBeforeScroll")
            insidePanel.classList.add("insidePanelEventsBeforeScroll")
            insidePanel.classList.remove("insidePanelEventsAfterScroll")
        }
    }

    useEffect(() => {
        if (window.document.getElementById("scrollable-events-panel-inside")) {

            window.document.getElementById("scrollable-events-panel").addEventListener('scroll', listenScrollEvent)
            window.document.getElementById("scrollable-events-panel-inside").addEventListener('scroll', listenScrollEvent)
        }
    }, [window.document.getElementById("scrollable-events-panel-inside")])

    return (
        <EventsLayout switchTheme={switchTheme} theme={theme} id={"scrollable-events-panel"}>
            <Box
                id="events"
                sx={{
                    ml: { xs: 'none', sm: '50px' },
                    display: 'flex',
                    flexDirection: 'column',
                    width: { xs: '100%', sm: 'calc(100% - 100px)' },
                    height: 'calc(100vh - 55px)',
                    gap: { xs: '4px', md: '10px' },
                    boxSizing: 'border-box', padding: '20px 15px 40px'
                }}>
                {globalUser.isLoggedIn ?
                    <>
                        <ProfileSlider id="events-slider" className="eventsSliderBeforeScroll" />

                        <ShowPanel sx={{
                            width: '100%',
                            flexDirection: 'column', boxSizing: 'border-box'
                        }}>
                            <Tabs
                                mb={'24px'}
                                jc={{ xs: 'start', md: 'center' }}
                            >
                                {
                                    activeTab !== 'create-event' ?
                                        <div onClick={() => setFilterOpen(prev => !prev)} style={{ display: 'flex', alignItems: 'center', color: '#787878', gap: '3px', cursor: 'pointer' }}>
                                            <ImageSearchOutlined sx={{ pb: '6px' }} />
                                            <Typography sx={{ fontSize: '14px', whiteSpace: 'nowrap' }}>
                                                Search & Filter
                                            </Typography>
                                            <KeyboardArrowDown sx={{ rotate: filterOpen ? '180deg' : '0deg', transition: '0.3s' }} />
                                        </div> : <></>
                                }
                                <Tab
                                    id={"home"}
                                    onClick={(e) => {
                                        setActiveTab(e.target.id)
                                        window.location.hash = `#${e.target.id}`
                                    }}
                                    text={'Events Home'}
                                    selected={activeTab == 'home'}
                                />
                                <Tab
                                    id={"joined-events"}
                                    onClick={(e) => {
                                        setActiveTab(e.target.id)
                                        window.location.hash = `#${e.target.id}`
                                    }}
                                    text={'Joined Events'}
                                    selected={activeTab == 'joined-events'}
                                />
                                <Tab
                                    id={"create-event"}
                                    onClick={(e) => {
                                        setActiveTab(e.target.id)
                                        window.location.hash = `#${e.target.id}`
                                    }}
                                    text={'Create Event'}
                                    selected={activeTab == 'create-event'}
                                />
                                <Tab
                                    id={"my-events"}
                                    onClick={(e) => {
                                        setActiveTab(e.target.id)
                                        window.location.hash = `#${e.target.id}`
                                    }}
                                    text={'My Events'}
                                    selected={activeTab == 'my-events'}
                                />
                            </Tabs>
                            <ScrollablePanel id="scrollable-events-panel-inside" className="insidePanelEventsBeforeScroll"
                                sx={{
                                    // transition: '500ms ease',
                                    display: 'flex', justifyContent: 'center', alignItems: 'start',
                                    p: {
                                        xs: '4px 4px 40px',
                                        sm: '4px 4px 20px',
                                    },
                                    boxSizing: 'border-box'
                                }}>
                                {activeTab == 'home' && <EventsHome filterOpen={filterOpen} />}
                                {activeTab == 'joined-events' && <JoinedEvents filterOpen={filterOpen} />}
                                {activeTab == 'create-event' && <CreateEvent />}
                                {activeTab == 'my-events' && <MyEvents filterOpen={filterOpen} />}
                            </ScrollablePanel>
                        </ShowPanel>
                    </>
                    :
                    <>you are not logged in </>}
            </Box>
            <ToastContainer position="bottom-center" autoClose={3000} hideProgressBar newestOnTop={false} closeOnClick pauseOnFocusLoss pauseOnHover />
            <VerifyPhoneModal openModal={openModal} setOpenModal={setOpenModal} />

        </EventsLayout >
    );

}

export default Events;