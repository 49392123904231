import { ChevronRight, KeyboardArrowDown } from "@mui/icons-material"
import { Box, styled } from "@mui/material"
import { useState } from "react"

const SingleEvent = styled(Box)(({ theme }) => ({
    boxSizing: 'border-box',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: theme.palette.secondary.bg,
    padding: '8px 12px 12px 8px',
    gap: '40px',
    borderRadius: '18px', transition: '500ms ease',
    boxShadow: theme.palette.primary.boxShadow,
    "@media (max-width: 600px)": {
        padding: '8px',
        gap: '20px',
    },
    marginBottom: '30px'
}))
const SingleEventAccordion = ({ children, details, height }) => {
    const [open, setOpen] = useState(false)

    return (
        <SingleEvent sx={{ flexDirection: 'column', gap: '0' }} onClick={() => setOpen(prev => !prev)}>
            <Box sx={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                gap: '40px',
                flexDirection: { xs: 'column', sm: 'row' }
            }}>
                {children}
                <KeyboardArrowDown sx={{ rotate: open ? '180deg' : '0', transition: '0.3s', fontSize: '30px', color: 'primary.darkGray' }} />
            </Box>

            <Box sx={{
                width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center',
                height: open ? height : '0px', transition: '0.3s', pt: '8px'
            }}>
                <Box sx={{
                    width: '100%', boxSizing: 'border-box',
                    overflow: 'hidden',
                    display: open ? 'flex' : 'none',
                    gap: { xs: '8px', md: '16px' }, flexDirection: 'column', borderTop: 'solid #eee 2px'
                }}>
                    <div onClick={(e)=>e.stopPropagation()}>
                        {details}
                    </div>
                </Box>
            </Box>

        </SingleEvent>
    )
}

export default SingleEventAccordion