import { Box, Typography, Accordion, AccordionDetails, AccordionSummary, CircularProgress, Modal, TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material'
import { ExpandMore } from '@mui/icons-material';
import { API_CONFIG } from '../../config'
import { useSelector } from "react-redux";
import { useEffect, useState } from 'react'
import styled from '@emotion/styled';
import { ArrowDown } from 'iconsax-react';
import ButtonPurple from '../buttons/buttonPurple';
import Pagination from "../pagination"


const Card = styled(Box)(({ theme }) => ({
    justifyContent: 'space-between',
    width: '100%',
    // maxWidth: '730px',
    borderRadius: '16px',
    display: 'flex',
    boxSizing: 'border-box',
    alignItems: 'center',
    padding: '16px',
    backgroundColor: theme.palette.secondary.bg,
    boxShadow: theme.palette.primary.boxShadow,
    gap: '16px',
}))

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.MuiTableCell-head`]: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
    },
    [`&.MuiTableCell-body`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

const UsersCheckouts = () => {
    const globalUser = useSelector(state => state.userReducer)
    const [openModal, setOpenModal] = useState(false)
    const [checkouts, setCheckouts] = useState(null)
    const [userInfo, setUserInfo] = useState(null)
    const [pgTabs, setPgTabs] = useState([1])
    const [selectedTab, setSelectedTab] = useState(1)

    useEffect(() => {
        if (globalUser.token) {
            getUsersCheckouts()
        }

    }, [globalUser.token])

    const getUsersCheckouts = async () => {
        let request = await fetch(`${API_CONFIG.ADMIN_API_URL}/checkouts/get/?from=0&to=1000`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${globalUser.token}`,
                'Content-Type': 'application/json',
            }
        })

        let response = await request.json()
        if (response.status === 200) {
            setCheckouts(response.data)
            let pages = []
            let tabNums = response.data.length / 15
            for (let i = 0; i < tabNums; i++) {
                pages.push(i + 1)
            }
            setPgTabs(pages)
        } else {
            console.log(response)
        }
    }

    const getUserWalletInfo = async (cid) => {
        let request = await fetch(`${API_CONFIG.PUBLIC_API_URL}/get-user-wallet-info/${cid}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${globalUser.token}`,
                'Content-Type': 'application/json',
            }
        })

        let response = await request.json()
        if (response.status === 200) {
            setUserInfo(response.data)
        } else {
            console.log(response)
        }
    }

    const handleOpenModal = (user) => {
        getUserWalletInfo(user.user_cid)
        setOpenModal(true)
    }

    const handleCloseModal = () => {
        setOpenModal(false)
        setUserInfo(null)
    }
    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%', gap: '20px' }}>
            <Typography sx={{ width: '100%', textAlign: 'center', fontSize: '22px', my: '24px' }}>Users Checkouts</Typography>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '90%', maxWidth: '800px', gap: '18px' }}>
                {
                    checkouts ?
                        <>
                            {
                                checkouts.slice((selectedTab - 1) * 15, ((selectedTab - 1) * 15) + 15).map((checkout, index) => (
                                    checkout.payment_status === 'paid' ?
                                        <Card>
                                            <Box sx={{ width: '84px', height: '84px', borderRadius: '18px', backgroundColor: 'primary.main', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                <ArrowDown size={'50px'} color="white" />
                                            </Box>
                                            <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', gap: '3px' }}>
                                                <Typography sx={{ color: 'primary.text', fontFamily: 'inter', fontSize: '16px', fontWeight: '500', lineHeight: '26px' }}>
                                                    <Typography sx={{ color: '#3DCA64', fontFamily: 'inter', fontSize: '16px', fontWeight: '500', lineHeight: '26px', display: 'inline-block' }}>Succcessfull&nbsp;</Typography>
                                                    Charge Wallet
                                                </Typography>
                                                <Typography sx={{ color: 'primary.text', textOverflow: 'ellipsis', whiteSpace: 'nowrap', fontFamily: 'Inter', fontSize: '14px', fontWeight: '400', lineHeight: '24px' }}>
                                                    Your wallet has been charged with {checkout.tokens} YouWho-tokens!
                                                </Typography>
                                                <Typography sx={{ color: 'primary.darkGray', fontFamily: 'inter', fontSize: '12px', fontWeight: '400', lineHeight: '24px' }}>{checkout.iat.substring(0, 10)} {checkout.iat.substring(11, 16)}</Typography>
                                            </Box>
                                            <ButtonPurple w={'max-content'} text={'More Info'} onClick={() => handleOpenModal(checkout)} px={'12px'} />
                                        </Card>
                                        :
                                        <Card>
                                            <Box sx={{ width: '84px', height: '84px', borderRadius: '18px', backgroundColor: 'primary.main', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                <ArrowDown size={'50px'} color="white" />
                                            </Box>
                                            <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', gap: '3px' }}>
                                                <Typography sx={{ color: 'primary.text', fontFamily: 'inter', fontSize: '16px', fontWeight: '500', lineHeight: '26px' }}>
                                                    <Typography sx={{ color: '#F675A8', fontFamily: 'inter', fontSize: '16px', fontWeight: '500', lineHeight: '26px', display: 'inline-block' }}>Fail&nbsp;</Typography>
                                                    Charge Wallet
                                                </Typography>
                                                <Typography sx={{ color: 'primary.text', textOverflow: 'ellipsis', whiteSpace: 'nowrap', fontFamily: 'Inter', fontSize: '14px', fontWeight: '400', lineHeight: '24px' }}>
                                                    The attempt to charge your wallet with {checkout.tokens} YouWho tokens failed.
                                                </Typography>
                                                <Typography sx={{ color: 'primary.darkGray', fontFamily: 'inter', fontSize: '12px', fontWeight: '400', lineHeight: '24px' }}>{checkout.iat.substring(0, 10)} {checkout.iat.substring(11, 16)}</Typography>
                                            </Box>
                                            <ButtonPurple w={'max-content'} text={'More Info'} onClick={() => handleOpenModal(checkout)} px={'12px'} />
                                        </Card>
                                ))
                            }
                            < Pagination tabs={pgTabs} selected={selectedTab} setSelectedTab={setSelectedTab} />
                        </>
                        : <CircularProgress />
                }
            </Box>
            <Modal
                open={openModal}
                onClose={handleCloseModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                disableScrollLock={true}

            >
                <Box sx={(theme) => ({
                    width: '100%',
                    height: '100%',
                    display: 'flex', alignItems: 'center', justifyContent: 'center',
                    backdropFilter: 'blur(10px)', pointerEvents: 'none'
                })}>
                    <Box sx={(theme) => ({
                        borderRadius: { xs: '0', sm: '24px' },
                        width: { xs: '100%', sm: '600px' }, height: { xs: '100%', sm: 'auto' },
                        minHeight: '400px',
                        backgroundColor: 'secondary.bg', boxShadow: theme.palette.primary.boxShadow, boxSizing: 'border-box',
                        display: 'flex', flexDirection: 'column',
                        justifyContent: 'center',
                        padding: '30px', alignItems: 'center', pointerEvents: 'all', overflow: 'hidden', maxHeight: '80vh'
                    })}>
                        {userInfo ? <TableContainer component={Paper}>
                            <Table sx={{ width: '100%' }} aria-label="customized table">
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell colSpan={2}>Checkout Information</StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {Object.keys(userInfo).map((key, index) => (
                                        <StyledTableRow>
                                            <StyledTableCell >{key}</StyledTableCell>
                                            <StyledTableCell >
                                                <Typography sx={{ width: '250px', overflow: 'auto' }}>
                                                    {JSON.stringify(userInfo[key])}
                                                </Typography>
                                            </StyledTableCell>
                                        </StyledTableRow>
                                    ))}
                                </TableBody>

                            </Table>
                        </TableContainer>

                            : <CircularProgress />}
                    </Box>
                </Box>
            </Modal>
        </Box>
    )
}

export default UsersCheckouts