import { Box, CircularProgress, Typography } from "@mui/material"
import { useEffect, useRef, useState } from "react"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router"
import { RelationCard } from "../utils"
import { API_CONFIG } from "../../config"
import Pagination from "../pagination"
import { toast } from "react-toastify"
import generateSignature from "../../utils/signatureUtils"

const MyFans = ({
    //  sendAllieRequest, sendFriendRequest, updateState, setUpdateState,
    shareClick,
    // removeAllie,
    // removeFollowing,
    searchResults, setAllFollowers
}) => {
    const globalUser = useSelector(state => state.userReducer)
    const apiCall = useRef(undefined)
    const [fans, setFans] = useState([])
    const [friends, setFriends] = useState(undefined)
    const [err, setErr] = useState(undefined)
    const [isRequested, setIsRequested] = useState(false)
    const [followings, setFollowings] = useState([])
    const navigate = useNavigate()
    const [from, setFrom] = useState(0)
    const [to, setTo] = useState(30)
    const [pgTabs, setPgTabs] = useState([1])
    const [selectedTab, setSelectedTab] = useState(1)
    const [flwReqs, setFlwReqs] = useState([])
    const [fansLoading, setFansLoading] = useState(true)
    const toastId = useRef(null);
    const loading = () => {
        toastId.current = toast.loading("Please wait...")
        // console.log(toastId)
    }
    const updateToast = (success, message) => {
        success ? toast.update(toastId.current, { render: message, type: "success", isLoading: false, autoClose: 3000 })
            : toast.update(toastId.current, { render: message, type: "error", isLoading: false, autoClose: 3000 })
    }
    const sendFriendRequest = async (receiver, sender) => {
        loading();
        let data = {
            owner_cid: sender,
            to_screen_cid: receiver,
        }
        let { requestData } = generateSignature(globalUser.privateKey, data)
        // console.log(requestData)
        let request = await fetch(`${API_CONFIG.AUTH_API_URL}/fan/send/friend-request`, {
            method: 'POST',
            body: JSON.stringify(requestData),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${globalUser.token}`,
            }
        })
        let response = await request.json()
        // console.log(response);
        if (response.message == "Updated Successfully") {
            updateToast(true, 'Followed')
            // setActiveTab(current_tab)
            getFollowings()
        } else {
            updateToast(false, response.message)
        }
    }
    const sendAllieRequest = async (receiver, sender,) => {
        loading();
        let data = {
            owner_cid: sender,
            to_screen_cid: receiver,
        }
        let { requestData } = generateSignature(globalUser.privateKey, data)
        // console.log(requestData)
        let request = await fetch(`${API_CONFIG.AUTH_API_URL}/fan/send/friend-request`, {
            method: 'POST',
            body: JSON.stringify(requestData),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${globalUser.token}`,
            }
        })
        let response = await request.json()
        // console.log(response);
        if (response.message == "Updated Successfully") {
            getFollowings()
            updateToast(true, 'Followed')
        } else {
            updateToast(false, response.message)
        }

    }
    const removeFollowing = async (receiver, sender) => {
        loading();
        let data = {
            owner_cid: sender,
            friend_screen_cid: receiver,
        }
        let { requestData } = generateSignature(globalUser.privateKey, data)
        // console.log(requestData)
        let request = await fetch(`${API_CONFIG.AUTH_API_URL}/fan/remove/following`, {
            method: 'POST',
            body: JSON.stringify(requestData),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${globalUser.token}`,
            }
        })
        let response = await request.json()
        // console.log(response);
        if (response.message == "Updated Successfully") {
            updateToast(true, 'Request Removed')
            // setActiveTab(current_tab)
            getFollowings()
        } else {
            updateToast(false, response.message)
        }
    }
    const removeAllie = async (receiver, sender) => {
        loading();
        let data = {
            owner_cid: sender,
            follower_screen_cid: receiver,
        }
        let { requestData } = generateSignature(globalUser.privateKey, data)
        // console.log(requestData)
        let request = await fetch(`${API_CONFIG.AUTH_API_URL}/fan/remove/follower`, {
            method: 'POST',
            body: JSON.stringify(requestData),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${globalUser.token}`,
            }
        })
        let response = await request.json()
        // console.log(response);
        if (response.message == "Updated Successfully") {
            updateToast(true, 'Follower Removed')
            getFollowings()

        } else {
            updateToast(false, response.message)
        }

    }
    const getFollowings = async () => {
        let request = await fetch(`${API_CONFIG.AUTH_API_URL}/fan/get/all/followings/`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${globalUser.token}`,
            }
        })
        let response = await request.json()
        // console.log('followings', response)

        if (!response.is_error) {
            setErr(undefined)
            // console.log(response)
            if (response.data.length > 0) {
                let tempFolls = []
                let tempreqs = []
                for (var i = 0; i < response.data.length; i++) {
                    for (var j = 0; j < response.data[i].friends.length; j++) {
                        if (response.data[i].friends[j].screen_cid == globalUser.YouWhoID && response.data[i].friends[j].is_accepted == true) {
                            tempFolls.push(response.data[i].user_wallet_info.screen_cid)
                        }
                        if (response.data[i].friends[j].screen_cid == globalUser.YouWhoID && response.data[i].friends[j].is_accepted == false) {
                            tempreqs.push(response.data[i].user_wallet_info.screen_cid)
                        }
                    }
                }
                setFollowings(tempFolls)
                setFlwReqs(tempreqs)

                // console.log(tempFolls)
            } else {
                setFollowings([])
            }
        } else {
            if (response.status == 404) {
                setFollowings([])

            } else {
                setErr(response.message)
                console.log(response.message)
            }
        }
    }
    const getFans = async () => {
        try {
            let request = await fetch(`${API_CONFIG.AUTH_API_URL}/fan/get/all/followers/?from=0&to=100`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${globalUser.token}`,
                }
            })
            let response = await request.json()
            // console.log('fans', response)

            if (!response.is_error) {
                setErr(undefined)

                if (response.data.friends.length > 0) {
                    //exclude followings from fans >>>
                    // if (followings && followings.length > 0) {
                    //     let tempFans = []
                    //     for (let i = 0; i < response.data.friends.length; i++) {
                    //         if (!followings.includes(response.data.friends[i].screen_cid)) {
                    //             tempFans.push(response.data.friends[i])
                    //         }
                    //     }
                    //     setFans(tempFans)
                    //     setAllFollowers(tempFans)
                    //     setFansLoading(false)

                    // } else {
                    //     setAllFollowers(response.data.friends)
                    //     setFans(response.data.friends)
                    //     setFansLoading(false)
                    // }
                    let fllsArr = response.data.friends.slice((selectedTab - 1) * 15, ((selectedTab - 1) * 15) + 15)
                    if (response.data.length >= 15) {
                        let pagTabs = []
                        let tabNums = response.data.length / 15
                        for (let i = 0; i < tabNums; i++) {
                            pagTabs.push(i + 1)
                        }
                        setPgTabs(pagTabs)
                        // console.log(tabNums)
                        // console.log(pagTabs)

                    } else {
                        console.log('getting fans !')
                    }

                    setAllFollowers(fllsArr)
                    setFans(fllsArr)
                    setFansLoading(false)

                } else {
                    setAllFollowers([])
                    setFans([])
                    setFansLoading(false)
                }
                // setUpdateState(false)
            } else {
                if (response.status == 404) {
                    setFans([])
                    setAllFollowers([])
                    setFansLoading(false)

                } else {
                    setErr(response.message)
                    console.log(response.message)
                }
            }

        } catch (error) {
            console.log(error)
        }
    }
    //exclude followings from fans >>
    useEffect(() => {
        if (globalUser.token) {
            getFollowings()
        }
    }, [globalUser.token])
    useEffect(() => {
        if (followings) {
            // console.log(followings, 'my followings')
            getFans()
        }
    }, [followings])
    useEffect(() => {
        if (to && followings)
            getFans()
    }, [to, followings])
    useEffect(() => {
        // setFrom((selectedTab - 1) * 15)
        setTo((((selectedTab - 1) * 15) + 15) + 15)
    }, [selectedTab])


    return (
        <>
            {err ? <Typography sx={{ textTransform: 'capitalize', color: 'primary.error' }}>{err}</Typography> :
                <>{fansLoading ? <CircularProgress /> :
                    <>{searchResults ?
                        <>
                            {searchResults.length > 0 ?
                                <>
                                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '15px', width: '100%' }}>
                                        {searchResults.map((fan, index) => (
                                            <RelationCard
                                                amFollowing={flwReqs.includes(fan.screen_cid)}
                                                isAccepted={false}
                                                role={'fan'}
                                                // amFollowing={false}
                                                getSuggestions={getFollowings}
                                                removeAllie={() => {
                                                    removeAllie(fan.screen_cid, globalUser.cid)
                                                }}
                                                image={fan.user_avatar} username={fan.username} allies={true}
                                                sendAllieRequest={() => sendAllieRequest(fan.screen_cid, globalUser.cid)}
                                                ywid={fan.screen_cid}
                                                removeFollowing={() => removeFollowing(fan.screen_cid, globalUser.cid)}

                                                sendFriendRequest={() => sendFriendRequest(fan.screen_cid, globalUser.cid)}
                                                shareClick={shareClick} />
                                        ))}
                                    </Box>
                                </>
                                : <Typography
                                    sx={{ color: 'primary.text', fontSize: { xs: '12px', sm: '14px' }, textTransform: 'capitalize' }}>
                                    No results
                                </Typography>}
                        </>
                        :
                        <>{fans.length > 0 ?
                            <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column', gap: '15px', width: '100%' }}>
                                {fans.map((fan, index) => (
                                    <RelationCard
                                        role={'fan'}
                                        amFollowing={flwReqs.includes(fan.screen_cid)}
                                        isAccepted={false}
                                        getSuggestions={getFollowings}
                                        removeAllie={() => {
                                            removeAllie(fan.screen_cid, globalUser.cid)
                                        }}
                                        image={fan.user_avatar} username={fan.username} allies={true}
                                        ywid={fan.screen_cid}

                                        sendAllieRequest={() => sendAllieRequest(fan.screen_cid, globalUser.cid)}
                                        removeFollowing={() => removeFollowing(fan.screen_cid, globalUser.cid)}

                                        sendFriendRequest={() => sendFriendRequest(fan.screen_cid, globalUser.cid)}
                                        shareClick={shareClick} />
                                ))}
                                <Box sx={{ mt: '30px' }}>
                                    <Pagination tabs={pgTabs} selected={selectedTab} setSelectedTab={setSelectedTab} />
                                </Box>
                            </Box>
                            : <Typography
                                sx={{ color: 'primary.text', fontSize: { xs: '12px', sm: '14px' }, textTransform: 'capitalize' }}>
                                Dear {globalUser.username} you dont have any allies yet</Typography>}
                        </>
                    }
                    </>
                }
                </>}
        </>
    );
}

export default MyFans;